import React from 'react';
import Web3 from 'web3';
// import * as anchor from '@project-serum/anchor';
import { PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import axios from 'axios';

import contractInfo from './../../amuletsContract.js';
import Modal from 'react-bootstrap/Modal'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import s1Vid from './../../assets/images/s1-video.mp4';
import eyeIcon from './../../assets/images/eye-icon.svg';
import hopinIcon from './../../assets/images/hopin-icon.svg';
import circleIcon from './../../assets/images/circle-icon.svg';
import openseaIcon from './../../assets/images/opensea-icon.svg';
import magicedenIcon from './../../assets/images/magiceden.svg';
import solseaIcon from './../../assets/images/SolSea_Logo.svg';
import facebookIcon from './../../assets/images/Facebook-big.svg';
import twitterIcon from './../../assets/images/Twitter-big.svg';
import s2Vid from './../../assets/images/S2-vid1.mp4';
import firstvid from './../../assets/images/S2-main-vid.mp4';
import { awaitTransactionSignatureConfirmation, getAmuletsMachine, mintOneToken, solana_connection } from '../../phantomService.js';
import solana_info from '../../solanaContract.js';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            count: 1,
            totalSupply: 0,
            currentPrice: 0,
            remainingCount: 0,
            maxCount: 20,
            process: false,
            alert1: '',
            alert2: '',
            alert3: '',
            alert4: '',
            s1tokenID: '',
            s1tokenstatus: '',
            mintableQty: 0,
            claimCount: 1,
            maxClaimCount: 50,
            processClaim: false,

            s2_totalSupply: 0,
            s2_mint: 0,
            s2_remaining: 0,
            s2_price: 0,
            s2_alert1: '',
            s2_mintable: false,
            s2_minting: false,
            s2_claimable: false,
            s2_claimModal: false,
            s2_solana_wallet: '',
            s2_presale_period: { 
                start: window.Configs.s2_presale_period.start, 
                end: window.Configs.s2_presale_period.end, 
                whitelist: window.Configs.s2_presale_period.whitelist 
            },
            s2_minting_result_show: false,
            s2_minting_tx: ''
        };

        this.eventDate = window.Configs.revealTime;
        // this.setState({ s2_presale_period: window.Configs.s2_presale_period });

        this.incrementCount = this.incrementCount.bind(this);
        this.decrementCount = this.decrementCount.bind(this);
        this.incrementMaxCount = this.incrementMaxCount.bind(this);
        this.buy = this.buy.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.setTokenID = this.setTokenID.bind(this);
        this.checkStatus = this.checkStatus.bind(this);
        this.incrementClaimCount = this.incrementClaimCount.bind(this);
        this.decrementClaimCount = this.decrementClaimCount.bind(this);
        this.incrementMaxClaimCount = this.incrementMaxClaimCount.bind(this);
        this.claimAMULETS = this.claimAMULETS.bind(this);
        
        const provider = new Web3.providers.HttpProvider(contractInfo.infura_url2);
        let web3 = new Web3(provider);
        const amuletsContract = new web3.eth.Contract(contractInfo.abi2, contractInfo.contract_address2);

        this.initialData(amuletsContract);
    }

    connectS2(){
        try {
            window.solana.connect();
            this.fetchSolanaInfo();
        } catch (error) {
            console.log(error);
        }
    }

    fetchSolanaInfo() {
        var anchorWallet;
        if (window.solana) {
            anchorWallet = { publicKey: window.solana.publicKey === null ? new PublicKey(solana_info.s2_treasury_address) : window.solana.publicKey };
        }
        else {
            anchorWallet = { publicKey: new PublicKey(solana_info.s2_treasury_address) };
        }
        getAmuletsMachine(anchorWallet, new PublicKey(solana_info.s2_machine_id), solana_connection).then(data => {
            // console.log(data);
            this.state.s2_totalSupply = data.itemsAvailable;
            this.state.s2_remaining = data.itemsRemaining;
            this.state.s2_mint = data.itemsRedeemed;
            this.state.s2_price = parseInt(data.state.data.price.toString())/LAMPORTS_PER_SOL;
            
            var currentTime = (new Date()).getTime();
            var walletAdd = window.solana.publicKey;
            if(walletAdd === null){
                walletAdd = "null";
            }else{

                if ( currentTime < this.state.s2_presale_period.start ) {
                    this.state.s2_mintable = false;
                    console.log("Minting not started")
                }
                else {
                    if ( currentTime >= this.state.s2_presale_period.start && currentTime <= this.state.s2_presale_period.end ) {
                            this.state.s2_mintable = true;
                            console.log("Minting started");                      
                    }
                    if ( currentTime > this.state.s2_presale_period.end ) {
                        this.state.s2_mintable = false;
                        console.log("Sales Closed")
                    }
                }
            }
        });
    }

    fetchGlobalFromDB() {
        axios.get(`${solana_info.s2_backend}/admin/globalsettings`).then(resp => {
            if ( resp.status === 200 ) {
                this.state.s2_totalSupply = resp.data.max_amulets;
                this.state.s2_remaining = parseInt(resp.data.max_amulets) - parseInt(resp.data.mint_presale);
                this.state.s2_mint = resp.data.mint_presale;
                this.state.s2_price = resp.data.mint_price;
                this.state.s2_mintable = false;
            }                
        });
    }
  
    async initialData(amuletsContract) {
        let totalSupply = await amuletsContract.methods.totalSupply().call();
        let currentPriceWei = await amuletsContract.methods.getPrice().call();
        let currentPrice = Web3.utils.fromWei(currentPriceWei, 'ether');
        let remainingCount = 10000 - totalSupply;
        let maxCount = this.state.maxCount;
        if (remainingCount < 20) maxCount = remainingCount;
        
        this.setState({ totalSupply: totalSupply, currentPrice: currentPrice, remainingCount: remainingCount, maxCount: maxCount });
    }

    componentDidMount() {
        this.getTimeDifference(this.eventDate);
        setInterval(() => this.getTimeDifference(this.eventDate), 1000);

        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.crossOrigin = "anonymous";
        document.getElementsByClassName("tweet")[0].appendChild(script);

        const script2 = document.createElement("script");
        script2.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v10.0";
        script2.crossOrigin = "anonymous";
        script2.async = true;
        script2.defer = true;
        script2.setAttribute("nonce", "SuD3RmqS");
        document.getElementById("fb-root").after(script2);

        // this.connectS2();

        if ( window.solana ) {
            window.solana.on('disconnect', ()=>{
                this.state.s2_mintable = false;
                // this.fetchSolanaInfo();
                console.log('Phantom wallet disconnected')
            });
            window.solana.on('connect', ()=>{
                // this.fetchSolanaInfo();
                console.log('Phantom wallet connected')
            });
        } else {
            // this.fetchGlobalFromDB();
        }
        // this.chkS2MintableQty();
    }

    async chkS2MintableQty() {
        let web3;
        if (window.ethereum) {
            try { 
                web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();
                if ( accounts.length > 0 ) {
                    await window.ethereum.request({ method: 'eth_requestAccounts' });
                    let netId = await web3.eth.net.getId();
                    if (netId === contractInfo.network_id) {
                        var eth_wallet = (await web3.eth.getCoinbase());
                        var mintableQty = await this.getMintableQty(eth_wallet);
                        this.setState({ mintableQty: mintableQty });

                    } else {
                        this.setState({alert3: 'Please select Ethereum Mainnet.'});
                    }
                }
            } catch(e) {
                console.log(e);
            }
        } else if (window.web3) {
            web3 = new Web3(window.web3.currentProvider);
        } else {
            this.setState({s2_alert1: 'Phantom wallet not installed on browser.'});
        }

    }

    leadingZero(num) {
        return (num < 10 && num >= 0) ? "0" + num : num;
    }
  
    getTimeDifference(eventDate) {
        const timezoneOffset = new Date().getTimezoneOffset() * 60000;
        const time = Date.parse(eventDate) - Date.parse(new Date()) - timezoneOffset;
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((time / 1000 / 60) % 60);
        const seconds = Math.floor((time / 1000) % 60);
        this.setState({ days, hours, minutes, seconds });     
    }
  
    incrementCount() {
        if ( this.state.count < this.state.maxCount ) {
            this.setState({count: this.state.count + 1});
        }
    }
    
    decrementCount() {
        if ( this.state.count > 1 ) {
            this.setState({count: this.state.count - 1});
        }
    }

    incrementMaxCount() {
        this.setState({count: 20});
    }

    incrementClaimCount() {
        if ( this.state.claimCount < this.state.mintableQty ) {
            this.setState({claimCount: this.state.claimCount + 1});
        }
    }
    
    decrementClaimCount() {
        if ( this.state.claimCount > 1 ) {
            this.setState({claimCount: this.state.claimCount - 1});
        }
    }

    async incrementMaxClaimCount() {
        let web3;
        if (window.ethereum) {
            try { 
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                web3 = new Web3(window.ethereum);
                
            } catch(e) {
                console.log(e);
            }
        } else if (window.web3) {
            web3 = new Web3(window.web3.currentProvider);
        } else {
            this.setState({alert1: 'Please install MetaMask to connect with website.'});
        }

        if (typeof web3 != "undefined") {
            const amuletsContract = new web3.eth.Contract(contractInfo.abi, contractInfo.contract_address);
            let netId = await web3.eth.net.getId();
            if (netId === contractInfo.network_id) {
                var eth_wallet = (await web3.eth.getCoinbase());
                let mintableQty = await this.getMintableQty(eth_wallet);
                let maxClaimCount = this.state.maxClaimCount;
                if (mintableQty < 50) maxClaimCount = mintableQty;
                this.setState({ mintableQty: mintableQty, maxClaimCount: maxClaimCount, claimCount: maxClaimCount });

                // amuletsContract.methods.checkmintableQty().call({from: (await web3.eth.getCoinbase()), type: "0x2"})
                // .then(response => {
                //     let mintableQty = response;
                //     let maxClaimCount = this.state.maxClaimCount;
                //     if (mintableQty < 50) maxClaimCount = mintableQty;
                //     this.setState({ mintableQty: mintableQty, maxClaimCount: maxClaimCount, claimCount: maxClaimCount });
                // })
                // .catch(err => alert(err.message));
            } else {
                this.setState({alert3: 'Please select Ethereum Mainnet.'});
            }
        }
    }

    async getMintableQty(eth_wallet) {
        var url = `${solana_info.s2_backend}/admin/chkmintableqty/${eth_wallet}`;
        var resp = await axios.get(url);
        if ( resp.status === 200 ) {
            return resp.data.mintableQty;
        }
        return 0;
    }

    async buy() {
        let web3;
        if (window.ethereum) {
            try { 
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                web3 = new Web3(window.ethereum);
                
            } catch(e) {
                console.log(e);
            }
        } else if (window.web3) {
            web3 = new Web3(window.web3.currentProvider);
        } else {
            this.setState({alert1: 'Please install MetaMask to connect with website.'});
        }

        if (typeof web3 != "undefined") {
            const amuletsContract = new web3.eth.Contract(contractInfo.abi2, contractInfo.contract_address2);
            let netId = await web3.eth.net.getId();
            if (netId === contractInfo.network_id2) {
                let hasSaleStarted = await amuletsContract.methods.hasSaleStarted().call();
                if (hasSaleStarted && this.state.remainingCount >= this.state.count && this.state.totalSupply < 10000) {
                    this.setState({process: true});
                    amuletsContract.methods.adoptAMULETS(this.state.count).send({from: (await web3.eth.getCoinbase()), value: Web3.utils.toWei((this.state.count * this.state.currentPrice).toString()), gasLimit: (this.state.count * 200000), type: "0x2"})
                    .then(response => {
                        this.initialData(amuletsContract);
                        this.setState({process: false});
                    })
                    .catch(err => alert(err.message));
                } else {
                    this.setState({alert2: 'Sales have not start.'});
                } 
            } else {
                this.setState({alert3: 'Please select Ethereum Mainnet.'});
            }
        }
    }

    async buyS2() {
        this.setState({ s2_minting: true });
        try {
    
            var anchorWallet = window.solana;
            anchorWallet = { 
                publicKey: new PublicKey(window.solana.publicKey.toString()),
                signAllTransactions: window.solana.signAllTransactions,
                signTransaction: window.solana.signTransaction 
            };
            var { amuletsMachine, itemsRedeemed } = await getAmuletsMachine(anchorWallet, new PublicKey(solana_info.s2_machine_id), solana_connection);
            // this.state.s2_minting = true;
            const mintTxId = await mintOneToken(
                amuletsMachine, 
                new PublicKey(solana_info.s2_machine_config),
                new PublicKey(window.solana.publicKey.toString()),
                new PublicKey(solana_info.s2_treasury_address)
            );
    
            const status = await awaitTransactionSignatureConfirmation(mintTxId, 30000, amuletsMachine.connection, 'singleGossip', false);
            
            this.setState({s2_minting_result_show: true, s2_minting_tx: mintTxId});
            console.log(mintTxId);
    
            console.log(status);
            
            var post_param = { mm_wallet: (""), pt_wallet: (window.solana.publicKey.toString()), machine_id: solana_info.s2_machine_id, mint_id: solana_info.s2_nft_list[itemsRedeemed], tx_hash: mintTxId };
            await axios.get(`${solana_info.s2_backend}/admin/addmintqty`, { params: post_param });
            this.fetchSolanaInfo();
            // window.location.reload();
        } catch (error) {
            
        }
        
    }

    handleMintModalClose() {
        this.setState({ s2_minting: false, s2_minting_result_show: false, s2_minting_tx: '' });
    }

    handleShow() {
        this.setState({alert1: ''});
        this.setState({alert2: ''});
        this.setState({alert3: ''});
        this.setState({alert4: ''});
    }

    setTokenID(value) {
        this.setState({s1tokenID: value});
        this.setState({s1tokenstatus: ''});
    }

    async checkStatus() {
        // console.log(123)
        if (this.state.s1tokenID === '') {
            this.setState({alert4: 'Please fill S1 Token ID.'});
        } else {
            // const provider = new Web3.providers.HttpProvider(contractInfo.infura_url2);
            // let web3 = new Web3(provider);
            // const amuletsContract = new web3.eth.Contract(contractInfo.abi2, contractInfo.contract_address2);

            // let s1TokenStatus = await amuletsContract.methods.s1tokenstatus(this.state.s1tokenID).call();
            // if (s1TokenStatus) {
            //     this.setState({s1tokenstatus: 'claimed'});
            // } else {
            //     this.setState({s1tokenstatus: 'unclaimed'});
            // }
            // let web3;
            // if (window.ethereum) {
            //     try { 
            //         await window.ethereum.request({ method: 'eth_requestAccounts' });
            //         web3 = new Web3(window.ethereum);
                    
            //     } catch(e) {
            //         console.log(e);
            //     }
            // } else if (window.web3) {
            //     web3 = new Web3(window.web3.currentProvider);
            // } else {
            //     this.setState({alert1: 'Please install MetaMask to connect with website.'});
            // }
            var resp = await axios.get(`${solana_info.s2_backend}/admin/claim_status_token/${this.state.s1tokenID}`);
            if ( resp.status === 200 ) {
                console.log(resp.data);
                if (resp.data.status === 1) {
                    this.setState({s1tokenstatus: 'claimed'});
                } else {
                    this.setState({s1tokenstatus: 'unclaimed'});
                }
            }
        }
    }

    async claimAMULETS() {
        let web3;
        if (window.ethereum) {
            try { 
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                web3 = new Web3(window.ethereum);
                
            } catch(e) {
                console.log(e);
            }
        } else if (window.web3) {
            web3 = new Web3(window.web3.currentProvider);
        } else {
            this.setState({alert1: 'Please install MetaMask to connect with website.'});
        }

        if (typeof web3 != "undefined") {
            const amuletsContract = new web3.eth.Contract(contractInfo.abi2, contractInfo.contract_address2);
            let netId = await web3.eth.net.getId();
            if (netId === contractInfo.network_id2) {
                this.setState({processClaim: true});
                amuletsContract.methods.claimAMULETS(this.state.claimCount).send({from: (await web3.eth.getCoinbase()), gasLimit: "200000", type: "0x2"})
                .then(response => {
                    this.initialData(amuletsContract);
                    this.setState({processClaim: false});
                })
                .catch(err => alert(err.message));
            } else {
                this.setState({alert3: 'Please select Ethereum Mainnet.'});
            }
        }
    }
    
    showClaimPopupModal() {
        this.setState({ s2_claimModal: true });
    }
    hideClaimPopupModal() {
        this.setState({ s2_claimModal: false });
    }
    setSolanaWallet(val) {
        this.setState({ s2_solana_wallet: val });
    }
    async claimAmuletsS2() {
        let web3;
        if (window.ethereum) {
            try { 
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                web3 = new Web3(window.ethereum);
                
            } catch(e) {
                console.log(e);
            }
        } else if (window.web3) {
            web3 = new Web3(window.web3.currentProvider);
        } else {
            this.setState({alert1: 'Please install MetaMask to connect with website.'});
        }
        var post_param = { mm_wallet: (await web3.eth.getCoinbase()), pt_wallet: this.state.s2_solana_wallet, num_amulets: this.state.claimCount };
        var resp = await axios.get(`${solana_info.s2_backend}/admin/calimamulets`, { params: post_param });
        // window.location.reload();
        this.setState({alert3:resp.data.message});
        this.hideClaimPopupModal();
        this.chkS2MintableQty();
    }

    render() {
        return (
            <article>
                <div id="fb-root"></div>
                
                <Modal show={this.state.alert1 !== '' || this.state.alert2 !== '' || this.state.alert3 !== '' || this.state.alert4 !== ''} onHide={this.handleShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>Alert</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.alert1 ? this.state.alert1 : (this.state.alert2 ? this.state.alert2 : (this.state.alert3 ? this.state.alert3 : this.state.alert4))}</Modal.Body>
                </Modal>

                <Modal show={this.state.s2_minting} backdrop="static" centered contentClassName={'dark-modal'}>
                    <Modal.Header >
                        <Modal.Title>Minting in Progress</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <section className="py-3">
                            {
                                this.state.s2_minting_result_show === true ? 
                                (
                                    <div>
                                        <p>SUCCESSFUL MINTED</p>
                                        <p>
                                            Tx ID : 
                                            <a href={`https://explorer.solana.com/tx/${this.state.s2_minting_tx}${solana_info.network === 'devnet' ? '?cluster=devnet' : ''}`} target="_blank" style={{ marginLeft: 20, color: '#ededff' }}>
                                                { this.state.s2_minting_tx.substr(0, 20) + '...' + this.state.s2_minting_tx.substr(-20, 20) }
                                            </a>
                                        </p>
                                        
                                        <div className="text-right">
                                            <button type="button" className="btn my-btn1 my-3" onClick={()=>this.handleMintModalClose()}>
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                )
                                : 
                                (
                                    <div className="minting-spinner">
                                        <div className="minting-spinner-text">Minting</div>
                                        <div className="minting-spinner-component red"></div>
                                        <div className="minting-spinner-component green"></div>
                                        <div className="minting-spinner-component blue"></div>
                                    </div>
                                )
                            }                            
                        </section>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.s2_claimModal} backdrop="static" centered onHide={()=>this.hideClaimPopupModal()} contentClassName={'dark-modal'}>
                    <Modal.Header closeButton>
                        <Modal.Title>CryptoAmuletsS2</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            What is your SOLANA wallet address?
                        </p>
                        <input type="text" id="claim_solana_wallet" className="text-center" placeholder="FKs2...tFiN" onChange={(e) => this.setSolanaWallet(e.target.value)} />
                        <div class="text-right">
                            <button type="button" disabled={ (this.state.s2_solana_wallet!=='' && this.state.s2_solana_wallet.length === 44 ) ? false : true} className="btn my-btn1 my-3" onClick={()=>this.claimAmuletsS2()}>
                                Claim
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                    
                {/* Section 1.1 */}
                <section id="crypto" className="py-3">
                    <div className="float-img line"></div>
                    <div className="container section-container position-relative">
                        <div className="row justify-content-between align-items-center flex-column-reverse flex-md-row">
                            <div className="col-xl-7 col-lg-6 col-md-6 mb-4 mb-lg-0 text-center text-md-left">
                                <h1 className="page-heading my-4 my-md-5 pt-md-3">Crypto<span className="second-color">Amulets</span></h1>
                                <h2 className="page-sub-heading ml-lg-5">Authentic <br /> Thai Amulets <br /> as <span className="second-color">NFT</span><span className="sup-text">s</span></h2>
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-6 px-0 px-lg-3">
                                <video className="logo-video w-100 cornerround" alt="Amulets main banner"autoPlay loop muted >
                                    <source src={firstvid} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Section 1.2 */}
                <section id="timer" className="pb-3 pb-md-5">
                    <div className="container section-container position-relative">
                        <div className="row justify-content-between align-items-center flex-column-reverse flex-md-row">
                            <div className="col-xl-7 col-lg-6 col-md-6 text-center text-md-left">
                                <div className="ml-lg-5 timer-left-box py-4 py-lg-0 my-1 my-lg-0">
                                    <div className="py-2 my-2 d-block d-md-none"><img src={eyeIcon} alt="Amulets Eye" className="eye-icon" /></div>
                                    <a href="/about-2" className="d-flex align-items-center justify-content-center justify-content-md-start">
                                        <span>About Series 2 (S2) <br /> on Solana <br /> by LP Somask</span>
                                        <span className="ml-2 d-none d-md-block"><img src={eyeIcon} alt="Amulets Eye" className="eye-icon" /></span>
                                    </a>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 col-md-6 px-0 px-lg-3">
                                <div className="timer-box p-3 w-100 cornerround">
                                    <div className="row justify-content-between align-items-center">
                                        <div className="col-xl-7 col-lg-6 col-12 text-center text-lg-left d-flex align-items-center justify-content-between flex-row flex-md-column">
                                            <div className="timer-heading-wrapper pb-0 pb-md-4 col-md-12 col-4 px-1">
                                                <div className="second-color">CryptoAmulets</div>
                                                <div className="third-color">Series 2</div>
                                            </div>
                                            <div className="timer-clock d-block d-md-none col-md-12 col-4 text-center px-1">
                                                <div className="counter">
                                                    <div className="circle">
                                                        <div className="slab" STYLE="--order: 0">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 1">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 2">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 3">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 3">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 4">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 5">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 6">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 7">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 8">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 9">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 10">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 11">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 12">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 13">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 14">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 15">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 16">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 17">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 18">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 19">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 20">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 21">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 22">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 23">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 24">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="dots">:</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="buy-btn-wrapper py-2 col-md-12 col-4 px-1">
                                                <a href="/#pricing" className="btn my-btn1">Hop in <span><img src={hopinIcon} alt="arrow icon" className="arrow-buy" /></span></a>
                                            </div>
                                        </div>
                                        <div className="col-xl-5 col-lg-6 col-12 text-center">
                                            <div className="pb-2 d-none d-md-block">
                                                <div className="counter">
                                                    <div className="circle">
                                                        <div className="slab" STYLE="--order: 0">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 1">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 2">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 3">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 3">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 4">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 5">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 6">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 7">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 8">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 9">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 10">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 11">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 12">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 13">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 14">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 15">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 16">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 17">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 18">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 19">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 20">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 21">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 22">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 23">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="slab" STYLE="--order: 24">
                                                            <div className="top"></div>
                                                        </div>
                                                        <div className="dots">:</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="time py-2">
                                                <div className="box day">
                                                    <div className="flex-column">
                                                        <div id="dd">{this.state.days >= 0 ? (this.leadingZero(this.state.days)) : '00'}</div>
                                                        <div className="title second-color">days</div>
                                                    </div>
                                                </div>
                                                <div className="box hour">
                                                    <div className="flex-column">
                                                        <div id="hh">{this.state.hours >= 0 ? (this.leadingZero(this.state.hours)) : '00'}</div>
                                                        <div className="title second-color">hours</div>
                                                    </div>
                                                </div>
                                                <div className="box min">
                                                    <div className="flex-column">
                                                        <div id="mm">{this.state.minutes >= 0 ? (this.leadingZero(this.state.minutes)) : '00'}</div>
                                                        <div className="title second-color">mins</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="line-divider-wrapper">
                    <div className="container">
                        <div className="line-divider"></div>
                    </div>    
                </section>

                {/* Section 2.1 */}
                <section id="pricing" className="text-center py-5">
                    <div className="container section-container">
                        <div className="row align-items-center mb-4 mb-lg-5">
                            <div className="col-lg-6 col-md-12">
                                <h2 className="sec-heading ml-md-5 text-center text-md-left">Get CryptoAmulets</h2>
                            </div>
                            <div className="col-lg-6 col-md-12 text-center text-md-left">
                                <p className="description mt-3 mt-lg-0">All CryptoAmulets NFTs went through blessing ceremony<br className="d-none d-lg-block" />
                                     conducted by the series respectively monks.
                                </p>
                            </div>    
                        </div>
                        <div className="row">
                            <Tabs className="w-100">
                                <TabList>
                                    <Tab>Series 2 — <span className="white">LP Somsak</span></Tab>
                                    <Tab>Series 1 — <span className="white">LP Heng</span></Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="series-content row mx-0 mx-lg-3">    
                                        <div className="col-xl-5 col-lg-6 col-md-12 p-0">
                                            <video className="logo-video" autoPlay loop muted >
                                                <source src={s2Vid} type="video/mp4" />
                                            </video>
                                        </div>
                                        <div className="col-xl-7 col-lg-6 col-md-12 p-0">
                                            <div className="float-img line"></div>
                                            <div className="info">
                                                <div className="date-price d-flex align-items-end justify-content-between">
                                                    <div className="title-wrapper text-left">
                                                        <div className="sub-heading mb-0">Get Your CryptoAmulets (Solana)</div> 
                                                        <h2 className="heading white mb-0 mt-2">Series 2  —  LP Somsak</h2> 
                                                    </div>
                                                    <div className="pop-label">
                                                        <span><img src={circleIcon} alt="Amulets supply" className="circle-icon" /></span>
                                                        <span className="white">Total</span> <span className="white"> 1200 </span> <span className="gold">NFT</span>
                                                    </div>
                                                </div>
                                                <div className="end-date">
                                                    <h3 className="label">Sales ended on 21 Nov 2021</h3>
                                                </div>
                                                <div className="etheum-details">
                                                    <div className="buy d-flex align-items-center justify-content-between align-items-xl-end">
                                                        <div className="secondary-market">
                                                            <h3 className="label">Purchase your CryptoAmulets S2<br /> from secondary market</h3>
                                                        </div>
                                                        <a href="https://solsea.io/collection/617ee6bb36fc4711a032b4fa" className="btn my-btn1 opensea-link"  target="_blank" rel="noreferrer">
                                                            <span>SolSea</span>
                                                            <span><img src={solseaIcon} alt="Opensea Icon" className="opensea-icon" /></span>
                                                        </a>
                                                        <a href="https://magiceden.io/marketplace/cryptoamulets_s2" className="btn my-btn1 opensea-link"  target="_blank" rel="noreferrer">
                                                            <span>MagicEden</span>
                                                            <span><img src={magicedenIcon} alt="Opensea Icon" className="opensea-icon" /></span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="series-content row mx-0 mx-lg-3">
                                        <div className="col-xl-5 col-lg-6 col-md-12 p-0">
                                            <video className="logo-video" autoPlay loop muted >
                                                <source src={s1Vid} type="video/mp4" />
                                            </video>
                                        </div>
                                        <div className="col-xl-7 col-lg-6 col-md-12 p-0">
                                            <div className="float-img line"></div>
                                            <div className="info">
                                                <div className="date-price d-flex align-items-end justify-content-between">
                                                    <div className="title-wrapper text-left">
                                                        <div className="sub-heading mb-0">Get Your CryptoAmulets (Ethereum)</div> 
                                                        <h2 className="heading white mb-0 mt-2">Series 1  —  LP Heng</h2> 
                                                    </div>
                                                    <div className="pop-label">
                                                        <span><img src={circleIcon} alt="Amulets supply" className="circle-icon" /></span>
                                                        <span className="white">Total</span> <span className="white"> 1680 </span> <span className="gold">NFT</span>
                                                    </div>
                                                </div>
                                                <div className="end-date">
                                                    <h3 className="label">Sales ended on 11 Jul 2021</h3>
                                                </div>
                                                <div className="etheum-details">
                                                    <div className="buy d-flex align-items-center justify-content-between align-items-xl-end">
                                                        <div className="secondary-market">
                                                            <h3 className="label">Purchase your CryptoAmulets<br /> from secondary market</h3>
                                                        </div>
                                                        <a href="https://opensea.io/assets/cryptoamulets?search[toggles][0]=BUY_NOW" className="btn my-btn1 opensea-link"  target="_blank" rel="noreferrer">
                                                            <span>Buy from OpenSea</span>
                                                            <span><img src={openseaIcon} alt="Opensea Icon" className="opensea-icon" /></span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </section>            

                <section className="line-divider-wrapper">
                    <div className="container">
                        <div className="line-divider"></div>
                    </div>    
                </section>

                {/* Section 2.2 */}
                {/* <section id="check-past" className="py-5">
                    <div className="container section-container">
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-8 col-md-12">
                                <h2 className="sec-heading ml-md-5 text-center text-md-left">For Series 1 (S1) owners</h2>
                            </div>
                        </div>
                        <div className="row mx-lg-0">
                            <div className="info-box col-lg-6 col-md-12 p-0 pr-lg-1 mb-3 mb-lg-0">
                                <div className="info cornerround">
                                    <div className="box-title mb-5">Check S1 Token claim status</div>
                                    <div className="box-label text-center mb-3">S1 Token ID</div>
                                    <div className="box-form text-center">
                                        <input type="text" id="token_id" className="text-center" placeholder="XXX-XXX" onChange={(e) => this.setTokenID(e.target.value)} />
                                        <button type="button" className="btn my-btn1 my-3" onClick={this.checkStatus}>
                                            Check S1 Token Status
                                            <span><img src={hopinIcon} alt="arrow icon" className="arrow-claim" /></span>
                                        </button>
                                    </div>
                                    <div className="box-form-result text-center mt-3">
                                        <div>{ this.state.s1tokenID !== '' ? ( 'S1 Token ID: ' + this.state.s1tokenID ) : '' }</div>
                                        <div>{ this.state.s1tokenstatus !== '' ? this.state.s1tokenstatus : '' }</div>
                                    </div>
                                </div>
                            </div>
                            <div className="info-box col-lg-6 col-md-12 p-0 pl-lg-1 mb-3 mb-lg-0">
                                <div className="info cornerround">
                                    <div className="box-title mb-5 mb-md-1">Claim my S2 Drops</div>
                                    <div className="box-label-small text-left mb-5 d-none d-md-block">2 unclaimed S1 NFT can claim a S2 NFT from 29 Oct - 5 Nov</div>
                                    <div className="box-label text-center mb-3">Total Drops Claimable: {this.state.mintableQty}</div>
                                    <div className="box-form text-center">
                                        <div className="claim">
                                            <div className="set-value">
                                                <span className="qty">Quantity</span>
                                                <div className="value-btns">
                                                    <button type="button" id="decrement" disabled={ this.state.mintableQty===0? true : false} onClick={this.decrementClaimCount}><span className="icon minus"></span></button>
                                                    <div id="count">{this.state.claimCount}</div>
                                                    <button type="button" id="increment" disabled={ this.state.mintableQty===0? true : false} onClick={this.incrementClaimCount}><span className="icon plus"></span></button>
                                                </div>
                                                <button type="button" className="btn max-btn" onClick={this.incrementMaxClaimCount}>Max</button>
                                            </div>
                                            <button type="button" disabled={ this.state.mintableQty===0? true : false} className="btn my-btn1 my-3" onClick={()=>this.showClaimPopupModal()}>
                                                Get my S2 Drops
                                                <span><img src={hopinIcon} alt="arrow icon" className="arrow-claim" /></span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="box-label-small text-center mb-2 d-block d-md-none">2 unclaimed S1 NFT can claim a S2 NFT from 29 Oct - 5 Nov</div>
                                    <div className="box-bottom-label text-right mt-3">Series 2 — <br /> LP Somsak</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                
                <section className="line-divider-wrapper">
                    <div className="container">
                        <div className="line-divider"></div>
                    </div>    
                </section>

                {/* Section 4 */}
                <section id="features" className="text-center py-5">
                    <div className="container section-container">
                        <div className="row align-items-center mb-5">
                            <div className="col-lg-6 col-md-12">
                                <h2 className="sec-heading ml-md-5 text-center text-md-left">S2 Features</h2>
                            </div>
                            <div className="col-lg-6 col-md-12 text-center text-md-left">
                                <p className="description">Below are some of the features that make up <br className="d-none d-lg-block" />Crypto Amulets S2
                                </p>
                            </div>    
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-6 mb-2 mb-xl-0 px-1 px-lg-2">
                                <div className="text-left item-card cornerround">
                                    <div className="position-relative item">
                                        <div className="icon squares"></div>
                                        <h4 className="sec-heading4 text-capitalize">Limited</h4>
                                        <p className="description">10000 Unique sacred collectibles blessed by LP Somsak and LP Heng</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-6 mb-2 mb-xl-0 px-1 px-lg-2">
                                <div className="text-left item-card cornerround">
                                    <div className="position-relative item">
                                        <div className="icon folders"></div>
                                        <h4 className="sec-heading4 text-capitalize">Full ownership</h4>
                                        <p className="description">Full control and unlimited commercial rights given to the owner over their NFT</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-6 mb-2 mb-xl-0 px-1 px-lg-2">
                                <div className="text-left item-card cornerround">
                                    <div className="position-relative item">
                                        <div className="icon medal"></div>
                                        <h4 className="sec-heading4 text-capitalize">Flat Pricing</h4>
                                        <p className="description">No more bonding curve. Each Amulets cost 0.5 SOL to mint</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-6 mb-2 mb-xl-0 px-1 px-lg-2">
                                <div className="text-left item-card cornerround">
                                    <div className="position-relative item">
                                        <div className="icon check"></div>
                                        <h4 className="sec-heading4 text-capitalize">Fair Distribution</h4>
                                        <p className="description">Blind draw with instant reveal. Token IDs are randomly assigned when minted</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-xl-3">
                            <div className="col-xl-3 col-6 mb-2 mb-xl-0 px-1 px-lg-2">
                                <div className="text-left item-card cornerround">
                                    <div className="position-relative item">
                                        <div className="icon transparent"></div>
                                        <h4 className="sec-heading4 text-capitalize">Transparent</h4>
                                        <p className="description">CryptoAmulets blessed and hosted on IPFS</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-6 mb-2 mb-xl-0 px-1 px-lg-2">
                                <div className="text-left item-card cornerround">
                                    <div className="position-relative item">
                                        <div className="icon community"></div>
                                        <h4 className="sec-heading4 text-capitalize">Community Wallet</h4>
                                        <p className="description">Community building, marketing and promotions</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-6 mb-2 mb-xl-0 px-1 px-lg-2">
                                <div className="text-left item-card cornerround">
                                    <div className="position-relative item">
                                        <div className="icon voting"></div>
                                        <h4 className="sec-heading4 text-capitalize">Amulets S2 DAO</h4>
                                        <p className="description">1 to 1 voting rights on use of Community Wallet and the future of this project</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-6 mb-2 mb-xl-0 px-1 px-lg-2">
                                <div className="text-left item-card cornerround">
                                    <div className="position-relative item">
                                        <div className="icon drops"></div>
                                        <h4 className="sec-heading4 text-capitalize">NFT drops</h4>
                                        <p className="description">Drops of next CryptoAmulets Series</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="line-divider-wrapper">
                    <div className="container">
                        <div className="line-divider"></div>
                    </div>    
                </section>

                {/* Section 5 */}
                <section id="roadmaps" className="home-roadmap text-center py-5">
                    <div className="bottom-bar"></div>
                    <div className="container section-container">
                        <div className="roadmap-inner d-flex align-items-center justity-content-center">
                            <div className="roadmap w-100">
                                <div className="roadmap-header-wrap mb-4 mb-lg-1">
                                    <h2 className="sec-heading ml-md-5 text-center text-md-left">S2 Roadmap</h2>
                                </div>
                                <div className="roadmap-body">
                                    <div className="roadmap-body-inner d-flex flex-wrap text-left">
                                        <div className="roadmap-item d-flex">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <div className="ml-lg-2 w-100">
                                                <h6 className="roadmap-item-number mb-3">20%</h6>
                                                <div className="roadmap-item-text"><p>300K THB donation to LP Somsak</p></div>
                                            </div>    
                                        </div>
                                        <div className="roadmap-item d-flex">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <div className="ml-lg-2 w-100">
                                                <h6 className="roadmap-item-number mb-3">40%</h6>
                                                <div className="roadmap-item-text"><p>Creation of Community wallet</p> <p>100 SOL added to Community Wallet</p></div>
                                            </div>    
                                        </div>
                                        <div className="roadmap-item d-flex">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <div className="ml-lg-2 w-100">
                                                <h6 className="roadmap-item-number mb-3">60%</h6>
                                                <div className="roadmap-item-text"><p>Development of CryptoAmulets S2 DAO</p> <p>50% of Royalties received to Community Wallet</p></div>
                                            </div>
                                        </div>
                                        <div className="roadmap-item d-flex">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <div className="ml-lg-2 w-100">
                                                <h6 className="roadmap-item-number mb-3">80%</h6>
                                                <div className="roadmap-item-text"><p>100% of Royalties received to Community Wallet</p></div>
                                            </div>
                                        </div>
                                        <div className="roadmap-item d-flex">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <div className="ml-lg-2 w-100">
                                                <h6 className="roadmap-item-number mb-3">100%</h6>
                                                <div className="roadmap-item-text"><p>1 to 1 drop of CryptoAmulets S3 for free</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Section 6 */}
                <section id="tweets-posts" className="py-5">
                    <div className="container section-container py-lg-5">
                        <div id="tweets" className="py-lg-5">
                            <div className="row justify-content-between">
                                <div className="col-lg-5 mb-5 mb-lg-0">
                                    <div className="social-heading d-flex align-items-center justify-content-between mb-3 mb-lg-5">
                                        <div className="col-lg-6 d-none d-lg-block">
                                            <h2 className="sec-heading text-left mb-3">Latest Tweets</h2>
                                        </div>
                                        <div className="col-lg-6 text-center text-lg-right mb-3">
                                            <img src={twitterIcon} alt="twitter icon" className="twitter-icon" />
                                        </div>
                                    </div>
                                    <div className="tweet">
                                        <a className="twitter-timeline" data-height="500" data-theme="dark" href="https://twitter.com/cryptoamulets?ref_src=twsrc%5Etfw">Tweets by cryptoamulets</a>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-auto w-100">
                                    <div className="social-heading d-flex align-items-center justify-content-between mb-3 mb-lg-5">
                                        <div className="col-lg-6 d-none d-lg-block">
                                            <h2 className="sec-heading text-left mb-3">Facebook Posts</h2>
                                        </div>
                                        <div className="col-lg-6 text-center text-lg-right mb-3">
                                            <img src={facebookIcon} alt="facebook icon" className="facebook-icon" />
                                        </div>
                                    </div>
                                    <div className="post">
                                        <div className="fb-page" data-href="https://www.facebook.com/cryptoamulets" data-tabs="timeline" data-width="500" data-height="500" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/cryptoamulets" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/cryptoamulets">‏‎Crypto Amulets‎‏</a></blockquote></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </article>
        )
    }
}

export default Home;