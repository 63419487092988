import React from 'react';
import somsak from './../../assets/images/img-lp-somsak.svg';
import traits from './../../assets/images/S2-Traits.png';
import futureplan from './../../assets/images/smart-contract-bg.svg';
import whyAmulet2 from './../../assets/images/S2bless.mp4';
import charm1 from './../../assets/images/charm-1.png';
import charm2 from './../../assets/images/charm-2.png';
import charm3 from './../../assets/images/charm-3.png';
import charm4 from './../../assets/images/charm-4.png';
import charm5 from './../../assets/images/charm-5.png';
import charm6 from './../../assets/images/charm-6.png';

const About2 = (props) => {
    return (
        <article>
            <section id="about" className="text-center pt-2 pb-5">
                <div className="container section-container position-relative">
                    <div className="row pb-3">
                        <div className="col-xl-5 col-lg-5 col-md-12 text-center text-lg-left pr-lg-1">
                            <div className="header-card cornerround h-100 p-3 p-lg-5">
                                <h2 className="sec-heading sec-heading2 text-lg-left">CryptoAmulets <br /> Series 2</h2>
                                <h2 className="sec-heading text-center d-block d-lg-none mb-3">
                                    <span className="heading-color2">Blessed by</span> <br /> <span className="heading-color3">LP Somsak</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12 text-center pl-1 d-none d-lg-block">
                            <div className="header-card cornerround h-100 p-5">
                                <h2 className="sec-heading text-center"><span className="heading-color2">Blessed by</span> <br /> <span className="heading-color3">LP Somsak</span></h2>
                            </div>
                        </div>
                    </div>

                    <div className="about-card p-2 p-lg-5">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 right-on-md">
                                <div className="info">
                                    <h2 className="sec-heading text-left mb-4"><span className="heading-color5">Who is</span> <br /> <span className="heading-color3">LP Somsak?</span></h2>
                                    <p className="description">Phra RatchaWorakhun, Luang Pu Somsak Panthito (LP Somsak) born on 24 October 1931 is the Abbot of Wat Buraparam, in Mueang Surin District of Thailand. 
                                    <br/><br/></p>
                                    <p className="description">LP Somsak, 90 years old, was ordained as a monk since the age of 20 and is one of the longest serving monks in Thailand. LP Somsak was bestowed the title of Phra RatchaWorakhun for his life accomplishments by the late King Rama IX.
                                    <br/><br/></p>
                                    <p className="description">LP Somsak very seldom conducts blessing ceremony for Amulets. Hence, Amulets by LP Somsak are extreme rare and hard to find.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 left-on-md">
                                <div className="ceo-img">
                                    <img className="img-fluid" src={somsak} alt="LP Somsak" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="crypto" className="pb-5">
                    <div className="float-img line"></div>
                </div>

                <div className="container section-container position-relative">
                    <div className="text-center px-lg-5 py-lg-4 px-0 py-0 mb-4 mb-lg-5">
                        <div className="row justify-content-between align-items-center">                      
                            <div className="col-lg-5 text-center">
                                <video className="cornerround" autoPlay loop muted>
                                    <source src={whyAmulet2} type="video/mp4" />
                                </video>
                            </div>
                            <div className="col-lg-7">
                                <h2 className="sec-heading text-left ml-0 ml-lg-5 mt-4 mt-lg-0 mb-4"><span className="heading-color3">Blessing by</span> <br /> <span className="heading-color5">LP Somsak & LP Heng</span></h2>
                                <p className="description text-left ml-0 ml-lg-5 mb-0 mb-lg-5">The Smart Contract, artworks and NFTs will be blessed by both <br className="d-none d-lg-block" /> LP Somsak & LP Heng to bring blessing and great fortune to their owners!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="roadmaps" className="about-roadmap about-s2 text-center pt-5 pb-3 pb-lg-5">
                    <div className="container section-container">
                        <div className="roadmap-inner row d-flex align-items-center justity-content-center px-0 px-lg-5">
                            <div className="roadmap w-100">
                                <div className="roadmap-header-wrap mb-5">
                                    <h2 className="roadmap-header sec-heading heading-color5 text-center text-lg-left">Project Dates</h2>
                                </div>
                                <div className="roadmap-body">
                                    <div className="roadmap-body-inner d-block d-md-flex flex-wrap text-left">
                                        <div className="roadmap-item">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <p className="roadmap-item-text mb-2">29 Oct 2021</p>
                                            <h6 className="roadmap-item-number mb-1">Claims Start</h6>
                                        </div>
                                        <div className="roadmap-item">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <p className="roadmap-item-text mb-2">3 Nov 2021</p>
                                            <h6 className="roadmap-item-number mb-1">Blessing Ceremony</h6>
                                        </div>
                                        <div className="roadmap-item">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <p className="roadmap-item-text mb-2">3 Nov 2021</p>
                                            <h6 className="roadmap-item-number mb-1">Presales</h6>
                                        </div>
                                        <div className="roadmap-item">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <p className="roadmap-item-text mb-2">6 Nov 2021</p>
                                            <h6 className="roadmap-item-number mb-1">Public Launch</h6>
                                        </div>
                                        <div className="roadmap-item">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <p className="roadmap-item-text mb-2">21 Nov 2021</p>
                                            <h6 className="roadmap-item-number mb-1">Sales End</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container section-container text-center my-0 my-lg-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="sec-heading mb-4 heading-color3">Traits</h2>
                            {/* <div className="ImgTraits mx-auto">
                                <img className="img-fluid" src={traits} alt="CryptoAmulets traits" />
                            </div> */}
                            
                            <div class="container3 section-container">
                                <div class="ImgTraits">
                                    <div class="img-wrapper">
                                        <img className="img-fluid" src={traits} alt="CryptoAmulets traits"/>
                                    </div>
                                    
                                    <div class="callout left a">
                                        <div class="inner">Crest</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout left b">
                                        <div class="inner">Border</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout left c">
                                        <div class="inner">Monk</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout left d">
                                        <div class="inner">Ornament Material</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout left e">
                                        <div class="inner">Crest</div>
                                        <div class="pointer"></div>
                                    </div>

                                    <div class="callout right f">
                                        <div class="inner">Charm</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout right g">
                                        <div class="inner">Aura</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout right h">
                                        <div class="inner">Amulet Material</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout right i">
                                        <div class="inner">Background</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout right j">
                                        <div class="inner">Frame</div>
                                        <div class="pointer"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div id="my-collection" className="text-center py-5">
                    <div className="container section-container position-relative">
                        <h3 className="sec-heading mb-3 heading-color5">Charms</h3>
                        <p className="description mb-5">Charms bring blessing and fortune to their owners.</p>
                        <div className="all-cards justify-content-center">
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm1} alt="CryptoAmulets Charm 1" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Suwan Singh</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm2} alt="CryptoAmulets Charm 2" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Na Lecha</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm3} alt="CryptoAmulets Charm 3" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Se Thi</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm4} alt="CryptoAmulets Charm 4" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Phrachao Ha Praong</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm5} alt="CryptoAmulets Charm 5" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Mong kut</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm6} alt="CryptoAmulets Charm 6" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Tri Chak</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="SmartContract" className="text-left py-5">    
                    <div className="container">    
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-12 px-0">
                                <figure className="position-relative cornerround FuturePlanImage">
                                    <img className="img-fluid cornerround w-100" src={futureplan} alt="Future" />
                                    <figcaption className="position-absolute d-flex justify-content-center align-items-center">
                                        <h2 className="sec-heading text-center">
                                            CryptoAmulets Program ID
                                            <span className="d-block pt-3 contract-address heading-color3">6sXWbCz7cMVscKQzrpmKf6moc2rsBucDHRVqp4gcqVPK</span>
                                        </h2>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </article>
    )
}

export default About2;