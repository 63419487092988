import React from 'react';
// import whyAmulet1 from './../../assets/images/why-amulet-1.mp4';
import whyAmulet2 from './../../assets/images/8pics.mp4';
// import whyAmulet3 from './../../assets/images/why-amulet-3.mp4';
import fairDestribution from './../../assets/images/img-fair-distribution.jpg';
import mechanism from './../../assets/images/img-mechanism.jpg';

const Why = (props) => {
    return (
        <article>
            {/*
            <section id="crypto" className="py-5">
                <div className="float-img object1"></div>
                <div className="float-img object2"></div>
                <div className="container section-container position-relative">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-7 mb-4 mb-md-0">
                            <h2 className="sec-heading mb-5">Get Them Now and Leave <br className="d-none d-lg-block" /> No Regrets Behind</h2>
                            <div className="text-center">
                                <NavLink className="btn my-btn1 text-capitalize" exact to="/#pricing">Buy Now</NavLink>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <video id="cornerround" autoPlay loop muted>
                                <source src={whyAmulet1} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </section>
            */}
            <section id="AmuletsReveal" className="py-5">
                {/* <div className="float-img object1"></div>
                <div className="float-img object2"></div> */}
                <div className="container section-container position-relative">
                    <div className="row justify-content-between align-items-center">                      
                        <div className="col-md-4 text-center">
                            <video className="cornerround" autoPlay loop muted>
                                <source src={whyAmulet2} type="video/mp4" />
                            </video>
                        </div>
                        <div className="col-md-8 text-md-right">
                            <h2 className="sec-heading text-white mr-0 mb-4 pt-3">The blessings of LP Heng</h2>
                            <p className="description text-white mb-2">The Smart Contract, artworks and NFTs are all blessed by guru monk, LP Heng of Wat Pattana Thammaram and should be considered as precious and sacred collectibles!</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* 
            <section id="AmuletsReveal" className="py-5 text-left">
                <div className="container section-container ">
                    <div className="row align-items-center justify-content-between">
                        <div  className="col-md-3 mb-3 mb-md-0">
                           <img id="cornerround" className="img-fluid" src={whyAmulet2} alt="LP Heng" /> 
                        </div>
                        <div className="col-md-8 text-md-right">
                            <h2 className="sec-heading text-white mr-0 mb-4">The blessings of LP Heng</h2>
                            <p className="description text-white mb-2">The Smart Contract, artworks and NFTs are all blessed by guru monk, LP Heng of Wat Pattana Thammaram and should be considered as precious and sacred collectibles!</p>
                        </div>
                    </div>
                </div>
            </section> 

            <section id="features" className="py-5">
                <div className="container section-container">
                    <div className="row align-items-center">
                        <div className="col-md-6 mb-3 mb-md-0">
                            <h2 className="sec-heading mb-4">Why Crypto Amulets?</h2>
                            <p className="description mb-4">Our revolutionary project is the ﬁrst of its kind on the blockchain. Thai amulet is highly valued because it has millions of collectors all around the globe with $1.25 billion of value traded every year!</p>
                            <p>Amulets blessed by venerated monks like LP Heng always sold out after launch. With only 8000 NFTS minted, there is a Huge potential!</p>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 mb-3">
                                    <div className="text-left item-card py-3 min-height-auto h-100">
                                        <div className="float-img object"></div>
                                        <div className="position-relative item">
                                            <div className="icon squares"></div>
                                            <h4 className="sec-heading4 text-capitalize mt-3">Limited</h4>
                                            <p className="description">8000 Unique sacred collectibles blessed by LP Heng</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 mb-3">
                                    <div className="text-left item-card py-3 min-height-auto h-100">
                                        <div className="float-img object"></div>
                                        <div className="position-relative item">
                                            <div className="icon folders"></div>
                                            <h4 className="sec-heading4 text-capitalize mt-3">Full ownership</h4>
                                            <p className="description">Full control and unlimited commercial rights given to the owner over their NFT</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="text-left item-card py-3 min-height-auto h-100">
                                        <div className="float-img object"></div>
                                        <div className="position-relative item">
                                            <div className="icon medal"></div>
                                            <h4 className="sec-heading4 text-capitalize mt-3">Good Merit</h4>
                                            <p className="description">Up to 18% of proceeds used for Donations</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="text-left item-card py-3 min-height-auto h-100">
                                        <div className="float-img object"></div>
                                        <div className="position-relative item">
                                            <div className="icon check"></div>
                                            <h4 className="sec-heading4 text-capitalize mt-3">Transparent</h4>
                                            <p className="description">Fairness in price and distribution with buyback mechanisms</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="GoodMerit" className="bg-dark text-white py-5">
                <div className="container section-container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-3 mb-3 mb-md-0">
                            <video id="cornerround" autoPlay loop muted>
                                <source src={whyAmulet3} type="video/mp4" />
                            </video>
                        </div>
                        <div className="col-md-6">
                            <h2 className="sec-heading mb-4">Good Merit</h2>
                            <p className="description mb-4">Sales proceeds received from the last 2 tiers (#7501-#8000) will be used for donations.</p>
                            <p>Users whom purchased CryptoAmulets from the last 2 tiers   during sales can contact us via Facebook for an official donation receipt for your tax or accounting purposes.</p>
                        </div>
                    </div>
                </div>
            </section>
            */}

            <section id="about" className="py-5">
                <div className="float-img object1"></div>
                <div className="float-img object2"></div>
                <div className="container section-container position-relative">
                    <div className="text-center mb-5">
                        <h2 className="sec-heading mb-4">Unique Pricing System</h2>
                        <p className="description">CryptoAmulets unique price system rewards early supporters for their investment and at <br className="d-none d-lg-block" /> the same time keeps it interesting for late adopters with the increased chance of <br className="d-none d-lg-block" /> opening a rare CryptoAmulets.</p>
                    </div>
                    <p className="description mb-3">There are a total of 8 tiers (71 rares) with its Price and Rare Chance as follows:</p>
                    <div className="table-responsive">
                        <table className="table table-striped table-dark">
                            <thead>
                            <tr>
                                <th scope="col">Tier</th>
                                <th scope="col">Box Type</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">Price (Eth)</th>
                                <th scope="col">Rare Chance</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Wood</td>
                                    <td>500</td>
                                    <td>0.02</td>
                                    <td>0.2%</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Stone</td>
                                    <td>1000</td>
                                    <td>0.04</td>
                                    <td>0.3%</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Steel</td>
                                    <td>1500</td>
                                    <td>0.08</td>
                                    <td>0.4%</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Digital</td>
                                    <td>2000</td>
                                    <td>0.16</td>
                                    <td>0.5%</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Gold</td>
                                    <td>1500</td>
                                    <td>0.3</td>
                                    <td>1%</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Pink Gold</td>
                                    <td>1000</td>
                                    <td>0.45</td>
                                    <td>2%</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Crystal</td>
                                    <td>400</td>
                                    <td>0.6</td>
                                    <td>3%</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Legendary</td>
                                    <td>100</td>
                                    <td>0.8</td>
                                    <td>4%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>

            <section id="FairDistribution" className="bg-dark text-white py-5">
                <div className="container section-container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6">
                            <h2 className="sec-heading mb-4">Fair Distribution</h2>
                            {/* <h3 className="Fsize_24 font-weight-bold line-height-11 mb-3">No one knows what artwork <br className="d-none d-lg-block" /> they get before Reveal.</h3> */}
                            <p className="description">CryptoAmulets’ artworks are stored in IPFS before Contract <br className="d-none d-lg-block" /> Creation and are only assigned to the Token ID after Reveal.  
                            </p>
                            <p className="description mt-3" >Original file containing SHA256 hash of each artwork - <br className="d-none d-lg-block" /> <a STYLE="font-size:10px" href="https://cryptoamulets.mypinata.cloud/ipfs/QmUP5AwjGSWbmgxbGVUyENJW31qidKBB9oyBTETd4R1YR4/8000%20Artwork%20Hash%20.xlsx" rel="noreferrer" target="_blank" >446A8BEB34E8049DF458F8C131A3F6F90394413E7DB2CC14F90D64DA820D30DE</a> </p>
                            <p className="description mt-3"> Reveal Hash - <br className="d-none d-lg-block" /> <a STYLE="font-size:10px" href="https://etherscan.io/tx/0x765b16050809392f09a381e43bad728a587b39bb3c5b0f70562a39bc090609ba" rel="noreferrer" target="_blank" >0x765b16050809392f09a381e43bad728a587b39bb3c5b0f70562a39bc090609ba</a></p>
                            <p className="description mt-3 mb-4"> Artworks allocation after distribution process - <br className="d-none d-lg-block" /> <a STYLE="font-size:10px" href="https://cryptoamulets.mypinata.cloud/ipfs/QmUP5AwjGSWbmgxbGVUyENJW31qidKBB9oyBTETd4R1YR4/Final%20Hash.xlsx" rel="noreferrer" target="_blank" >2744EEAB4B91D7D6A91BF963AC897FEA76E08070BA75A537F369568C59B8E268</a> </p>
                        </div>
                        <div className="col-md-6">
                            <figure>
                                <img className="rounded img-fluid mb-3" src={fairDestribution} alt="Amulets Fair Distribution" />
                                <figcaption className="text-center line-height-11">
                                    The Transaction Hash of the Reveal transaction block will be used with our unique technique to determine the artwork assigned to the Token ID. This technique guarantees fair distribution and also maintain our unique price system concept.
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </section>

            <section id="gallery" className="py-5">
                <div className="container section-container">
                    {/* <div className="row"> */}
                        {/* <div className="col-md-6 mb-4 mb-md-0"> */}
                            <h2 className="sec-heading mb-4">Buy Back Mechanism</h2>
                            <div className="row">
                                <div className="col-md-4 mb-4 mb-md-0">
                                    <img className="cornerround img-fluid mb-4" src={mechanism} alt="Amulets Mechanism" />
                                </div>
                                <div className="col-md-8">
                                    <p className="description mb-3">The whole point behind this is to look after this project and make it sustainable in time.</p>
                                    <p className="description mb-3">Royalty fee (5%) received from transaction sale on Opensea will be used to purchase the lowest priced CryptoAmulets listed on Opensea.</p>
                                    <p className="description mb-1">Bought back CryptoAmulets will be displayed on the Vault Page and put out of circulation.</p>
                                </div>    
                            </div>
                        {/* </div> */}
                        {/* <div className="col-md-6">
                            <h2 className="sec-heading mb-4">Support for Cash Buyers</h2>
                            <img id="cornerround" className="img-fluid mb-4" src={support} alt="Amulets Support" />
                            <p className="description mb-3">We provide support for cash buyers using THB only. Users can transfer THB to our bank account and we will purchase the CryptoAmulets on their behalf
                                The cost will be based on ETH market price on bitkrub.com exchange. ETH/THB Price X 10% charge. This is to cover the exchange fee, transfer fee and gas fee for the purchase.</p>
                            <p className="description mb-3">CryptoAmulets via such purchase will be displayed on the Vault page together with the name of their owners under Purchase For section.</p>
                            <p className="description">Interested parties can contact us via our Facebook Page for more information.</p>
                        </div> */}
                    {/* </div> */}
                </div>
            </section>
        </article>
    )
}

export default Why;