import React from 'react';
import * as Containers from './containers/index';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Layout from './layouts/Layout';
import ScrollToTop from './libs/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom.scss';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Layout exact path="/" component={Containers.Home} header={true} footer={true} />
        <Layout exact path="/about" component={Containers.About} header={true} footer={true} />
        <Layout exact path="/about-2" component={Containers.About2} header={true} footer={true} />
        <Layout exact path="/how-to-buy" component={Containers.How} header={true} footer={true} />
        <Layout exact path="/faq" component={Containers.FAQ} header={true} footer={true} />
        <Layout exact path="/gallery" component={Containers.Gallery} header={true} footer={true} />
        <Layout exact path="/vault" component={Containers.Vault} header={true} footer={true} />
        <Layout exact path="/why-amulets" component={Containers.Why} header={true} footer={true} />
        <Layout exact path="/collections" component={Containers.Collections} header={true} footer={true} />
        <Layout exact path="/item1/:id" component={Containers.Item} header={true} footer={true} />
        <Layout exact path="/item2/:id" component={Containers.Item2} header={true} footer={true} />
        <Layout exact path="/press" component={Containers.Press} header={true} footer={true} />
        {/* <Layout exact path="/bless1" component={Containers.Bless1} header={true} footer={true} />
        <Layout exact path="/bless2" component={Containers.Bless2} header={true} footer={true} />
        <Layout exact path="/bless3" component={Containers.Bless3} header={true} footer={true} /> 
        <Layout exact path="/bless4" component={Containers.Bless4} header={true} footer={true} />  */}
      </Switch>
    </Router>
  );
}

export default App;
