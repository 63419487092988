import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import logo from './../assets/images/amulets-logo-white.svg';
import discord from './../assets/images/discord.svg';
import telegram from './../assets/images/telegram.svg';
import twitter from './../assets/images/twitter.svg';
import facebook from './../assets/images/facebook.svg';
import medium from './../assets/images/medium.svg';
import opensea from './../assets/images/opensea.svg';
import hopinIcon from './../assets/images/hopin-icon.svg';

const Footer = (props) => {
    return (
        <footer id="footer">
            <div className="footer pt-5 pb-2 light">
                <div className="container section-container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6 text-center text-sm-left">
                            <div className="navbar-brand logo mb-4">
                                <img className="img-fluid" src={logo} alt="Crypto Amulets" />
                            </div>
                            <p className="description mb-4">
                                Our unique concept merges authentic Thai Amulets and Ethereum Smart Contract into a truly unique NFT! Get these glorious and historical NFTs as part of your digital collections today!
                            </p>
                        </div>
                        <div className="col-lg-7 col-md-6">
                            <div className="row">
                                <div className="col-lg-3 col-6 text-center text-sm-left">
                                    <ul className="links">
                                        <li>
                                            <NavLink exact to="/#pricing">Buy</NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact to="/collections">My Collections</NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact to="/vault">Vault</NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact to="/press">Press</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-3 col-6 text-center text-sm-left">
                                    <ul className="links">
                                        <li>
                                            <NavLink exact to="/about">About S1</NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact to="/about-2">About S2</NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact to="/faq">FAQ</NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact to="/gallery">Gallery</NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-lg-6 col-md-12 mt-5 mt-md-0">
                                    <p className="sub-heading text-center text-md-left">Newsletter</p>
                                    <div id="mc_embed_signup">
                                        <form action="https://cryptoamulets.us5.list-manage.com/subscribe/post?u=891a93e8378c18acd7a73ab52&amp;id=3baa6c83e1" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                            <div id="mc_embed_signup_scroll">
                                                <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Enter your email" required />
                                                <div STYLE="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_891a93e8378c18acd7a73ab52_3baa6c83e1" tabIndex="-1" value="" /></div>
                                                <div className="clear"><button type="submit" name="subscribe" id="mc-embedded-subscribe" className="btn w-100 my-btn1">Subscribe now <span><img src={hopinIcon} alt="arrow icon" className="arrow-buy" /></span></button></div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <ul className="social-icons">
                                <li>
                                    <a href="https://discord.com/invite/KezUNfkG4Q" className="icon discord" rel="noreferrer" target="_blank">
                                        <img className="img-fluid" src={discord} alt="Discord" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/cryptoamulets" className="icon twitter" rel="noreferrer" target="_blank">
                                        <img className="img-fluid" src={twitter} alt="Twitter" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/cryptoamulets/" className="icon facebook" rel="noreferrer" target="_blank">
                                        <img className="img-fluid" src={facebook} alt="Facebook" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://cryptoamulets.medium.com/cryptoamulets-first-thai-amulets-as-nfts-in-the-metaverse-39def4b2eac8" className="icon medium" rel="noreferrer" target="_blank">
                                        <img className="img-fluid" src={medium} alt="Medium" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://t.me/joinchat/_9LeMRPpZ6ZjYzE9" className="icon telegram" rel="noreferrer" target="_blank">
                                        <img className="img-fluid" src={telegram} alt="Telegram" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://opensea.io/assets/cryptoamulets?search[toggles][0]=BUY_NOW" className="icon opensea" rel="noreferrer" target="_blank">
                                        <img className="img-fluid" src={opensea} alt="Opensea" />
                                    </a>
                                </li>
                                
                                
                            </ul>
                        </div>
                    </div>
                    <div className="text-center text-md-left coprights">
                        <p>Copyrights 2021. All Right Reserved by <span className="primary-color"><span className="white">Crypto</span>Amulets</span></p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;