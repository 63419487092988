import React, { useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import faqHeaderIcon from './../../assets/images/faq-header-icon.svg';

const FAQ = (props) => {

    const [activeId, setActiveId] = useState('0');

    function toggleActive(id) {
        if (activeId === id) {
        setActiveId(null);
        } else {
        setActiveId(id);
        }
    }
    
    return (
        <article>
            <section id="faq" className="text-center pt-2 pb-5">
                <div className="container section-container position-relative">
                    <div className="row pb-3 pb-lg-5">
                        <div className="col-xl-7 col-lg-7 col-md-12 text-center text-lg-left pr-lg-1">
                            <div className="header-card cornerround h-100 p-3 p-lg-5">
                                <h2 className="sec-heading mb-2 mb-lg-4 text-lg-left">Frequently asked questions</h2>
                                <p className="description">Everything you need to know about CryptoAmulets</p>
                                <img src={faqHeaderIcon} alt="Amulets FAQ" className="faq-header-icon d-block d-lg-none w-25 m-auto pt-3" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-12 text-center pl-1 d-none d-lg-block">
                            <div className="header-card cornerround h-100 p-5">
                                <img src={faqHeaderIcon} alt="Amulets FAQ" className="faq-header-icon" />
                            </div>
                        </div>
                    </div>
                    
                    <Accordion id="faqAccordion" className="pb-5" defaultActiveKey={activeId}>
                        <Card className={activeId === '0' ? 'active' : ''}>
                            <Accordion.Toggle onClick={() => toggleActive('0')} as={Card.Header} eventKey="0">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <div className="faq-title">
                                            <span className="faq-icon"></span>
                                            <span className="question">What are CryptoAmulets?</span>
                                        </div>    
                                        <span className="eye-icon"></span>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body className="cornerround">
                                    <p><span class="gold-bold">CryptoAmulets </span>are authentic Thai Amulets collectibles minted as NFTs on the blockchain. All CryptoAmulets went through blessing ceremony conducted by monks of their respective Series.</p>
                                    <p>- CryptoAmulets on Ethereum by <span class="gold-bold">LP Heng </span>blessed on 9 Jun 2021 (Sales period 11 Jun – 11 Jul)</p>
                                    <p>- CryptoAmulets S2 on Solana by <span class="gold-bold">LP Somsak </span>blessed on 3 Nov 2021 (Sales period 3 Nov – 21 Nov)</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={activeId === '1' ? 'active' : ''}>
                            <Accordion.Toggle onClick={() => toggleActive('1')} as={Card.Header} eventKey="1">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <div className="faq-title">
                                            <span className="faq-icon"></span>
                                            <span className="question">Why CryptoAmulets?</span>
                                        </div>
                                        <span className="eye-icon"></span>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body className="cornerround">
                                    <p>Thai Amulets have existed for hundreds of years with many Amulets being produced each year. The large volume of Amulets leads to inefficiency in trades as verification of its history and originality becomes an issue over time. Authentication process can be difficult, non-transparent, and even debatable.</p>
                                    <p>Beside using blockchain technology to assist with Amulets’ authentication, CryptoAmulets is the first with the concept to have the Amulets in the blockchain itself.</p>
                                    <p>CryptoAmulets are immutable and authentic. They are easy to carry in digital wallets and tradable in secondary markets.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={activeId === '2' ? 'active' : ''}>
                            <Accordion.Toggle onClick={() => toggleActive('2')} as={Card.Header} eventKey="2">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <div className="faq-title">
                                            <span className="faq-icon"></span>
                                            <span className="question">How are the NFTs blessed?</span>
                                        </div>
                                        <span className="eye-icon"></span>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body className="cornerround">
                                    <p>Puttapisek is the ceremony in which incantations are chanted over the targeted medium by a group of monks to empower it with blessing.</p>
                                    <p class="gold-bold">CryptoAmulets S1</p>
                                    <p>Images of all Amulets stored in IPFS storage, together with its Smart Contract details, are printed out for Puttapisek blessing.</p>
                                    <p>This method is time consuming and expensive. Hence, improvements were made.</p>
                                    <p class="gold-bold">CryptoAmulets S2</p>
                                    <p>Since all Amulets data are stored immutability on IPFS, a Dapp was developed to display the Amulets in timed rotations during blessing.</p>
                                    <p>Displays connected to the website containing the Dapp will interact with the Smart Contract and retrieve the Amulets images for blessing.</p>
                                    <p>In this way, everything digital are being blessed.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={activeId === '3' ? 'active' : ''}>
                            <Accordion.Toggle onClick={() => toggleActive('3')} as={Card.Header} eventKey="3">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <div className="faq-title">
                                            <span className="faq-icon"></span>
                                            <span className="question">What is IPFS?⁣⁣</span>
                                        </div>
                                        <span className="eye-icon"></span>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body className="cornerround">
                                    <p>IPFS, Inter Planetary File System, is a file system that allows the storing of immutable files and relies on cryptographic hashes to be stored on a blockchain.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={activeId === '4' ? 'active' : ''}>
                            <Accordion.Toggle onClick={() => toggleActive('4')} as={Card.Header} eventKey="4">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <div className="faq-title">
                                            <span className="faq-icon"></span>
                                            <span className="question">What is random mint?</span>
                                        </div>
                                        <span className="eye-icon"></span>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body className="cornerround">
                                    <p>Token IDs are randomly assigned out when minting. Minters will not get the Token IDs in sequential order which is a fairer way of distribution.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className={activeId === '5' ? 'active' : ''}>
                            <Accordion.Toggle onClick={() => toggleActive('5')} as={Card.Header} eventKey="5">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <div className="faq-title">
                                            <span className="faq-icon"></span>
                                            <span className="question">Price of CryptoAmulets?</span>
                                        </div>
                                        <span className="eye-icon"></span>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body className="cornerround">
                                    <p>CryptoAmulets S2 – Flat fee of 0.5 SOL per mint</p>
                                    <p>CryptoAmulets S1 – 1 - 500 @ 0.02 ETH | 501- 1500 @ 0.04 ETH | 1501 – 1680 @ 0.08 ETH</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        {/* <Card className={activeId === '6' ? 'active' : ''}>
                            <Accordion.Toggle onClick={() => toggleActive('6')} as={Card.Header} eventKey="6">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <div className="faq-title">
                                            <span className="faq-icon"></span>
                                            <span className="question">How to buy?</span>
                                        </div>
                                        <span className="eye-icon"></span>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="6">
                                <Card.Body className="cornerround">
                                    <p class="gold-bold">For CryptoAmulets S2</p>
                                    <p>1. Install <a href="https://phantom.app/" rel="noreferrer" target="_blank" class="gold-bold">Phantom</a> wallet</p>
                                    <p>2. Deposit Solana (SOL) into your Phantom wallet with the amount of SOL you want to spend (including gas costs)</p>
                                    <p>3. Connect your Phantom wallet with our website via web browser</p>
                                    <p>4. Click Mint (1 NFT per transaction)</p>
                                    <p>5. Approve the purchase on Phantom and wait for transaction confirmation</p>
                                    <p>6. Repeat steps 4-5 to mint additional amulets</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card> */}
                        {/* <Card className={activeId === '7' ? 'active' : ''}>
                            <Accordion.Toggle onClick={() => toggleActive('7')} as={Card.Header} eventKey="7">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <div className="faq-title">
                                            <span className="faq-icon"></span>
                                            <span className="question">Claiming of CryptoAmulets S2 NFT?</span>
                                        </div>
                                        <span className="eye-icon"></span>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="7">
                                <Card.Body className="cornerround">
                                    <p>To claim one S2 NFT, you need to have at least two unclaimed S1 NFT in your Metamask wallet.</p>
                                    <p>1) Connect your metamask wallet</p>
                                    <p>2) Enter Max quantity you are claiming</p>
                                    <p>3) Click on the 'Get my S2 Drop' button</p>
                                    <p>4) Enter your Solana wallet address and click confirm</p>
                                    <p>Your S2 NFT will be airdrop to your Solana wallet <span class="gold-bold">within 24hrs after the blessing ceremony</span></p>
                                    <p>You can also check the claim status of your S1 NFT in main page</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card> */}
                        <Card className={activeId === '8' ? 'active' : ''}>
                            <Accordion.Toggle onClick={() => toggleActive('8')} as={Card.Header} eventKey="8">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <div className="faq-title">
                                            <span className="faq-icon"></span>
                                            <span className="question">Marketplace for CryptoAmulets?</span>
                                        </div>
                                        <span className="eye-icon"></span>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="8">
                                <Card.Body className="cornerround">
                                    <p>CryptoAmulets S1 - OpenSea</p>
                                    <p>CryptoAmulets S2 - Solsea , Magiceden and DigitalEyes</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        {/* <Card className={activeId === '9' ? 'active' : ''}>
                            <Accordion.Toggle onClick={() => toggleActive('9')} as={Card.Header} eventKey="9">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <div className="faq-title">
                                            <span className="faq-icon"></span>
                                            <span className="question">How to get Whitelisted for Presale?</span>
                                        </div>
                                        <span className="eye-icon"></span>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="9">
                                <Card.Body className="cornerround">
                                    <p>Join our <a href="https://discord.com/invite/nV78mMJhqD" rel="noreferrer" target="_blank" class="gold-bold">Discord</a> and request for an Early Member role in #chat-general for Presale Whitelist access</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card> */}
                    </Accordion>
                </div>
            </section>
        </article>
    )
}

export default FAQ;