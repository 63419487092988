import React from 'react';
import Web3 from 'web3';
import contractInfo from './../../amuletsContract.js';
import getPrice from '../../libs/GetPrice';
import ListTraits from '../../components/item/ListTraits';
import linkIcon from './../../assets/images/link.svg';

class Item extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            index: this.props.match.params.id,
            image: '',
            name: '',
            price: getPrice(this.props.match.params.id),
            priceUSD: '',
            owner: '',
            description: '',
            openseaURI: window.Configs.openseaURI + this.props.match.params.id,
            attributes: []
            
        };
    }

    componentDidMount() {
        const provider = new Web3.providers.HttpProvider(contractInfo.infura_url);
        let web3 = new Web3(provider);

        const amuletsContract = new web3.eth.Contract(contractInfo.abi , contractInfo.contract_address);

        this.initialData(amuletsContract);
    }

    async initialData(amuletsContract) {

        let baseURI = await amuletsContract.methods.baseURI().call();
        let owner = await amuletsContract.methods.ownerOf(this.state.index).call();
        fetch(baseURI + this.state.index)
            .then(res => res.json())
            .then((result) => { 
                    this.setState({
                        image: result.image,
                        name: result.name,
                        owner: owner,
                        attributes: result.attributes,
                        description: result.description
                    });
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    render() {
        const renderListTraits = (item) => <ListTraits item={item} key={item.trait_type} />
        const renderTraits = () => this.state.attributes.map(renderListTraits);

        return (
            <article>
                <section id="nft-detail" className="pt-2 pb-5">
                    <div className="container section-container position-relative">
                        <div className="main-card">
                            <div className="d-block d-lg-flex">
                                <div className="col-lg-5 px-0 pr-lg-1">
                                    <div className="card-img cornerround">
                                        {this.state.image.includes('.mp4') ? (
                                            <video autoPlay loop muted>
                                                <source src={this.state.image} type="video/mp4" />
                                            </video>
                                        ) : (
                                            <img className="img-fluid cornerround" src={this.state.image} alt={this.state.name} />
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-7 px-0 pl-lg-1 pt-2 pt-lg-0">
                                    <div className="right-side cornerround h-100 p-3 py-lg-4 px-lg-5">                                       
                                        <h2>{this.state.name}</h2>
                                        <h5>Series 1 - LP Heng</h5>
                                        
                                        <div className="chain-info pt-3 pt-lg-5 pb-3">
                                            <div className="d-block d-lg-flex py-3 underline">
                                                <div className="gold"><p>Description</p></div>
                                                <div className="dark"><p>{this.state.description}</p></div>
                                            </div>
                                            <div className="d-block d-lg-flex py-3 underline">
                                                <div className="gold"><p>Owner Address</p></div>
                                                <div className="dark"><p>{this.state.owner}</p></div>
                                            </div>
                                            <div className="d-block d-lg-flex py-3 underline">
                                                <div className="gold"><p>Blockchain:</p></div>
                                                <div className="dark"><p>Ethereum</p></div>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <a href={this.state.openseaURI} className="btn my-btn1 py-3 px-5" target="_blank" rel="noreferrer">
                                                Buy
                                                <img src={linkIcon} alt="Amulets Opensea link" className="link-icon" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="attributes my-4 my-lg-5 p-0 p-lg-5">
                            <div className="d-flex align-items-center justify-content-between underline pb-2 mb-3">
                                <h3>Traits</h3>
                                {/* <div className="info-icon"><span>i</span></div> */}
                            </div>
                            <div className="row">
                                {renderTraits()}
                            </div>
                        </div>
                    </div>
                </section>
            </article>
        )
    }
}

export default Item;