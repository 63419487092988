import React from 'react';
import heng from './../../assets/images/img-lp-heng.svg';
import traits from './../../assets/images/S1-Traits.png';
import star from './../../assets/images/star.svg';
import book from './../../assets/images/book-icon.svg';
import futureplan from './../../assets/images/smart-contract-bg.svg';
import whyAmulet2 from './../../assets/images/8pics.mp4';
import arrowDown from './../../assets/images/arrow-down.svg';
import arrowTriple from './../../assets/images/tripple-arrow.svg';
import charm1 from './../../assets/images/charm-s1.png';
import charm2 from './../../assets/images/charm-s2.png';
import charm3 from './../../assets/images/charm-s3.png';
import charm4 from './../../assets/images/charm-s4.png';
import charm5 from './../../assets/images/charm-s5.png';
import charm6 from './../../assets/images/charm-s6.png';
import charm7 from './../../assets/images/charm-s7.png';
import charm8 from './../../assets/images/charm-s8.png';
import handwritten1 from './../../assets/images/about-hand-written-1.png';
import handwritten2 from './../../assets/images/about-hand-written-2.png';
import handwritten3 from './../../assets/images/about-hand-written-3.png';

const About = (props) => {
    return (
        <article>
            <section id="about" className="text-center pt-2 pb-5">
                <div className="container section-container position-relative">
                    <div className="row pb-3">
                        <div className="col-xl-5 col-lg-5 col-md-12 text-center text-lg-left pr-lg-1">
                            <div className="header-card cornerround h-100 p-3 p-lg-5">
                                <h2 className="sec-heading sec-heading2 text-lg-left">CryptoAmulets <br /> Series 1</h2>
                                <h2 className="sec-heading text-center d-block d-lg-none mb-3">
                                    <span className="heading-color2">Blessed by</span> <br /> <span className="heading-color3">LP Heng</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-12 text-center pl-1 d-none d-lg-block">
                            <div className="header-card cornerround h-100 p-5">
                                <h2 className="sec-heading text-center"><span className="heading-color2">Blessed by</span> <br /> <span className="heading-color3">LP Heng</span></h2>
                            </div>
                        </div>
                    </div>
                    
                    <div className="about-card p-2 p-lg-5">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 right-on-md">
                                <div className="info">
                                    <h2 className="sec-heading text-left mb-4"><span className="heading-color5">Who is</span> <br /> <span className="heading-color3">LP Heng ?</span></h2>
                                    <p className="description">Luang Pu Heng Paphaso (LP Heng) born on 11 August 1927 resides at Wat Pattana Thammaram temple, in Surin Province of Thailand. LP Heng, a 95yrs old monk, is a grand master of spells and charms.
                                    <br/><br/></p>
                                    <p className="description">LP Heng has a rich history span all through his existence. Many miracle stories and encounters of LP Heng and his amulets or charm can be found in the internet.
                                    <br/><br/></p>
                                    <p className="description">LP Heng Amulets are highly sought for its luck enhancement, wealth fetching, protection, miracle happening and success. The temple counter hardly has any of his amulet available as they are all snatched up by collectors during sales launch.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-12 left-on-md">
                                <div className="ceo-img">
                                    <img className="img-fluid" src={heng} alt="LP Heng" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="crypto" className="pb-5">
                    <div className="float-img line"></div>
                </div>

                <div className="container section-container position-relative">
                    <div className="text-center px-lg-5 py-lg-4 px-0 py-0 mb-4 mb-lg-5">
                        <div className="row justify-content-between align-items-center">                      
                            <div className="col-lg-5 text-center">
                                <video className="cornerround" autoPlay loop muted>
                                    <source src={whyAmulet2} type="video/mp4" />
                                </video>
                            </div>
                            <div className="col-lg-7">
                                <h2 className="sec-heading text-left ml-0 ml-lg-5 mt-4 mt-lg-0 mb-4"><span className="heading-color3">First digital amulets</span> <br /> <span className="heading-color5">ever created with</span> <br /> <span className="heading-color5">blessing by LP Heng</span></h2>
                                <p className="description text-left ml-0 ml-lg-5 mb-0 mb-lg-5">The Smart Contract, artworks and NFTs are all blessed by guru monk, LP Heng to bring blessing and great fortune to their owners!</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="roadmaps" className="about-roadmap about-s1 text-center pt-5 pb-3 pb-lg-5">
                    <div className="container section-container">
                        <div className="roadmap-inner row d-flex align-items-center justity-content-center px-0 px-lg-5">
                            <div className="roadmap w-100">
                                <div className="roadmap-header-wrap mb-5">
                                    <h2 className="roadmap-header sec-heading heading-color5 text-center text-lg-left">Project Dates</h2>
                                </div>
                                <div className="roadmap-body">
                                    <div className="roadmap-body-inner d-block d-md-flex flex-wrap text-left">
                                        <div className="roadmap-item">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <p className="roadmap-item-text mb-2">9 Jun 2021</p>
                                            <h6 className="roadmap-item-number mb-1">Blessing Ceremony</h6>
                                        </div>
                                        <div className="roadmap-item">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <p className="roadmap-item-text mb-2">11 Jun 2021</p>
                                            <h6 className="roadmap-item-number mb-1">Sales Start</h6>
                                        </div>
                                        <div className="roadmap-item">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <p className="roadmap-item-text mb-2">16 Jun 2021</p>
                                            <h6 className="roadmap-item-number mb-1">Artwork Reveal</h6>
                                        </div>
                                        <div className="roadmap-item">
                                            <div className="roadmap-item-point"><span></span></div>
                                            <p className="roadmap-item-text mb-2">11 Jul 2021</p>
                                            <h6 className="roadmap-item-number mb-1">Sales End</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container section-container text-center my-0 my-lg-5">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="sec-heading mb-4 heading-color3">Traits</h2>
                            <div class="container2 section-container">
                                <div class="ImgTraits">
                                    <div class="img-wrapper">
                                        <img className="img-fluid" src={traits} alt="CryptoAmulets traits"/>
                                    </div>

                                    <div class="callout left a">
                                        <div class="inner">Left Charm</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout left b">
                                        <div class="inner">Background</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout left c">
                                        <div class="inner">Aura</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout left d">
                                        <div class="inner">Border</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout left e">
                                        <div class="inner">Base Material</div>
                                        <div class="pointer"></div>
                                    </div>

                                    <div class="callout right f">
                                        <div class="inner">Right Charm</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout right g">
                                        <div class="inner">Monk Material</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout right h">
                                        <div class="inner">Robe</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout right i">
                                        <div class="inner">Crest</div>
                                        <div class="pointer"></div>
                                    </div>
                                    <div class="callout right j">
                                        <div class="inner">Frame</div>
                                        <div class="pointer"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div id="features" className="py-5">
                    <div className="container section-container">
                        <div className="row align-items-center px-3 px-lg-5">
                            <div className="col-lg-12">
                                <div className="row">
                                    <h2 className="sec-heading mb-4 mb-lg-5 heading-color5 text-center text-lg-left w-100">Features</h2>
                                </div>
                                <div className="row">
                                    <div className="col-xl-3 col-6 mb-2 px-1 px-lg-2">
                                        <div className="text-left item-card cornerround">
                                            <div className="position-relative item">
                                                <div className="icon squares"></div>
                                                <h4 className="sec-heading4 text-capitalize mt-3">Limited</h4>
                                                <p className="description">1680 Unique sacred collectibles blessed by LP Heng</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-6 mb-2 px-1 px-lg-2">
                                        <div className="text-left item-card cornerround">
                                            <div className="position-relative item">
                                                <div className="icon folders"></div>
                                                <h4 className="sec-heading4 text-capitalize mt-3">Full ownership</h4>
                                                <p className="description">Full control and unlimited commercial rights given to the owner over their NFT</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-6 mb-2 px-1 px-lg-2">
                                        <div className="text-left item-card cornerround">
                                            <div className="position-relative item">
                                                <div className="icon medal"></div>
                                                <h4 className="sec-heading4 text-capitalize mt-3">Good Merit</h4>
                                                <p className="description">Up to 18% of proceeds used for Donations</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-6 mb-2 px-1 px-lg-2">
                                        <div className="text-left item-card cornerround">
                                            <div className="position-relative item">
                                                <div className="icon transparent"></div>
                                                <h4 className="sec-heading4 text-capitalize mt-3">Transparent</h4>
                                                <p className="description">Fairness in price and distribution with buyback mechanisms</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div id="features-2" className="py-0 py-lg-5">
                    <div className="container section-container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row px-3 px-lg-5">
                                    <h2 className="sec-heading my-4 heading-color5 text-left">Roadmap</h2>
                                    <p className="description mb-3 mr-0 mr-lg-5 text-left">There are a total of 8 tiers (71 rares) with prices as follows.</p>
                                    <p className="description mb-3 mr-0 mr-lg-5 text-left">1680 out of 8000 CryptoAmulets were sold before sales end.</p>
                                    <div className="features-roadmap-item">
                                        <span className="features-roadmap-item-star"><img className="img-fluid" src={star} alt="CryptoAmulets star" /></span>
                                        <span className="features-roadmap-item-text">Royalty fees (5%) received from OpenSea to purchase the floor.</span>
                                    </div>
                                    <div className="features-roadmap-item">
                                        <span className="features-roadmap-item-star"><img className="img-fluid" src={star} alt="CryptoAmulets star" /></span>
                                        <span className="features-roadmap-item-text">Floor bought back are displayed in the Vault and put out of circulation.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 mb-3 mb-md-0">
                                <div className="table-responsive cornerround p-3 mt-3 mt-lg-0">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">Tier</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Quantity</th>
                                            <th scope="col">Roadmap for Tier completion</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>0.02 <span className="eth-color">ETH</span></td>
                                                <td>500</td>
                                                <td className="no-border"></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>0.04 <span className="eth-color">ETH</span></td>
                                                <td>1000</td>
                                                <td className="no-border"></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>0.08 <span className="eth-color">ETH</span></td>
                                                <td>1500</td>
                                                <td><span className="roadmap-color">Construct a 3m high Buddha statue for LP Heng (not met)</span></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>0.16 <span className="eth-color">ETH</span></td>
                                                <td>2000</td>
                                                <td><span className="roadmap-color">Donate 4 ICU ventilators to hospitals in Surin (not met)</span></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>0.30 <span className="eth-color">ETH</span></td>
                                                <td>1500</td>
                                                <td><span className="roadmap-color">Donate books, toys, clothing and food to 2000 orphans (not met)</span></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>0.45 <span className="eth-color">ETH</span></td>
                                                <td>1000</td>
                                                <td><span className="roadmap-color">Construct a 8m high structure of the amulet’s model (not met)</span></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>0.60 <span className="eth-color">ETH</span></td>
                                                <td>400</td>
                                                <td className="no-border"></td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>0.80 <span className="eth-color">ETH</span></td>
                                                <td>100</td>
                                                <td><span className="roadmap-color">Tier 7–8 proceeds to be donated to Temples in Surin (not met)</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div id="my-collection" className="text-center py-5">
                    <div className="container section-container position-relative">
                        <h3 className="sec-heading mb-3 heading-color5">Charms</h3>
                        <p className="description mb-5">Charms bring blessing and fortune to their owners.</p>
                        <div className="all-cards justify-content-center">
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm1} alt="CryptoAmulets Charm 1" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Hah Taew</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm2} alt="CryptoAmulets Charm 2" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Gao Yord</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm3} alt="CryptoAmulets Charm 3" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Paed Tidt</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm4} alt="CryptoAmulets Charm 4" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Ha PraOng</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm5} alt="CryptoAmulets Charm 5" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Dok Bua</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm6} alt="CryptoAmulets Charm 6" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Mongkut</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm7} alt="CryptoAmulets Charm 7" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Phokasap</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={charm8} alt="CryptoAmulets Charm 8" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Maha Sa Wang</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="my-collection" className="text-center py-5">
                    <div className="container section-container position-relative">
                        <h4 className="sec-heading mb-3 heading-color5">LP Heng Hand Written Charms</h4>
                        <div className="all-cards justify-content-center">
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={handwritten1} alt="LP Heng Hand Written Charms" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">PhraChao Ha PraOng</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={handwritten2} alt="LP Heng Hand Written Charms" />
                                     </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Mahaut</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4 mb-2 mb-lg-3 px-1 px-lg-2">
                                <div className="item-card">
                                    <div className="bg-color">
                                        <img className="img-fluid" src={handwritten3} alt="LP Heng Hand Written Charms" />
                                    </div>
                                    <div className="detail-box">
                                        <div className="text-capitalize card-name text-center">Sethi</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div id="AmuletsReveal" className="py-5 text-left">
                    <div className="container section-container">
                        <div className="row justify-content-between py-3">
                            <div className="col-lg-6">
                                <div className="row px-3 px-lg-5">
                                    <h2 className="sec-heading heading-color5 mb-4">Fair Distribution</h2>
                                    <p className="description pb-3">CryptoAmulets’ artworks are stored in IPFS before Contract <br className="d-none d-lg-block" /> Creation and are only assigned to the Token ID after Reveal.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row reveal-row underline py-3">
                                    <div className="col-xl-5 col-lg-6 d-flex align-items-center">
                                        <span className="reveal-item-book"><img className="img-fluid" src={book} alt="CryptoAmulets book" /></span>
                                        <span className="reveal-item-text">Original file containing SHA256 hash of each artwork</span>
                                    </div>
                                    <div className="col-xl-7 col-lg-6 mt-2 mt-md-0">
                                        <a href="https://cryptoamulets.mypinata.cloud/ipfs/QmUP5AwjGSWbmgxbGVUyENJW31qidKBB9oyBTETd4R1YR4/8000%20Artwork%20Hash%20.xlsx" rel="noreferrer" target="_blank" >446A8BEB34E8049DF458F8C131A3F6F90394413E7DB2CC14F90D64DA820D30DE</a>
                                    </div>
                                </div>
                                <div className="row reveal-row underline py-3">
                                    <div className="col-xl-5 col-lg-6 d-flex align-items-center">
                                        <span className="reveal-item-book"><img className="img-fluid" src={book} alt="CryptoAmulets book" /></span>
                                        <span className="reveal-item-text">Reveal Hash</span>
                                    </div>
                                    <div className="col-xl-7 col-lg-6 mt-2 mt-md-0">
                                        <a href="https://etherscan.io/tx/0x765b16050809392f09a381e43bad728a587b39bb3c5b0f70562a39bc090609ba" rel="noreferrer" target="_blank" >0x765b16050809392f09a381e43bad728a587b39bb3c5b0f70562a39bc090609ba</a>
                                    </div>
                                </div>
                                <div className="row reveal-row underline py-3">
                                    <div className="col-xl-5 col-lg-6 d-flex align-items-center">
                                        <span className="reveal-item-book"><img className="img-fluid" src={book} alt="CryptoAmulets book" /></span>
                                        <span className="reveal-item-text">Final artworks allocation after distribution process</span>
                                    </div>
                                    <div className="col-xl-7 col-lg-6 mt-2 mt-md-0">
                                        <a href="https://cryptoamulets.mypinata.cloud/ipfs/QmUP5AwjGSWbmgxbGVUyENJW31qidKBB9oyBTETd4R1YR4/Final%20Hash.xlsx" rel="noreferrer" target="_blank" >2744EEAB4B91D7D6A91BF963AC897FEA76E08070BA75A537F369568C59B8E268</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-3 px-0 px-lg-1">
                                <div className="distribution-1 cornerround h-100 p-2 p-lg-3">
                                    SHA256 Hash file of 8000 CryptoAmulets Artwork
                                </div>
                            </div>
                            <div className="col-9 px-0 px-lg-1">
                                <div className="distribution-12 cornerround h-100">
                                    <div className="distribution-12-top d-flex align-items-end pt-1 pt-lg-3">
                                        <div className="col-5 px-0 pb-2">
                                            <ul className="artID-1 d-flex align-items-center justify-content-center flex-wrap">
                                                <li>1</li>
                                                <li>2</li>
                                                <li>3</li>
                                                <li>...</li>
                                                <li>70</li>
                                                <li>71</li>
                                            </ul>
                                        </div>
                                        <div className="col-2 px-1">
                                            <div className="artID-label">Art ID</div>
                                        </div>
                                        <div className="col-5 px-0 pb-2">
                                            <ul className="artID-2 d-flex align-items-center justify-content-center flex-wrap">
                                                <li>72</li>
                                                <li>73</li>
                                                <li>...</li>
                                                <li className="lw">7999</li>
                                                <li className="lw">8000</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="distribution-12-bottom d-flex align-items-end pt-1 pt-lg-3">
                                        <div className="col-5 text-center pb-2">
                                            <div className="rare-btn">Rare</div>
                                        </div>
                                        <div className="col-2 px-0">
                                            <div className="artID-label">SHA256<br className="d-block d-md-none" />Hash</div>
                                        </div>
                                        <div className="col-5 text-center pb-2">
                                            <div className="rare-btn">Common</div>
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 px-0 px-lg-1"></div>
                            <div className="col-9 px-0 px-lg-1">
                                <div className="d-flex align-items-end">
                                    <div className="col-5 text-center">
                                        <img className="img-arrow-down" src={arrowDown} alt="CryptoAmulets arrow" />
                                    </div>
                                    <div className="col-2 px-0"></div>
                                    <div className="col-5 text-center">
                                        <img className="img-arrow-down" src={arrowDown} alt="CryptoAmulets arrow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 px-0 px-lg-1">
                                <div className="distribution-1 cornerround h-100 p-2 p-lg-3">
                                    Sequencing based on Reveal Transaction Hash
                                </div>
                            </div>
                            <div className="col-9 px-0 px-lg-1">
                                <div className="distribution-22 cornerround h-100">
                                    <div className="d-flex align-items-end text-center">
                                        <div className="col-6 px-0 pb-2 panel-border-right">
                                            <div className="layer-label p-1 p-lg-3">Rare</div>
                                            <div className="layer-title p-1 p-lg-3">New ArtID =</div>
                                            <div className="layer-detail px-3 mb-3 mb-md-5">(ArtID + Last 8 digit of Reveal Tnx Hash) mod71</div>
                                        </div>
                                        <div className="col-6 px-0 pb-2">
                                            <div className="layer-label p-1 p-lg-3">Common</div>
                                            <div className="layer-title p-1 p-lg-3">New ArtID =</div>
                                            <div className="layer-detail px-3 mb-3 mb-md-5">(ArtID + Last 8 digit of Reveal Tnx Hash) mod7929</div>
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 px-0 px-lg-1"></div>
                            <div className="col-9 px-0 px-lg-1">
                                <div className="d-flex align-items-end">
                                    <div className="col-6 text-center">
                                        <img className="img-arrow-down" src={arrowTriple} alt="CryptoAmulets arrow" />
                                    </div>
                                    <div className="col-6 text-center">
                                        <img className="img-arrow-down" src={arrowTriple} alt="CryptoAmulets arrow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 px-0 px-lg-1">
                                <div className="distribution-1 cornerround h-100 p-2 p-lg-3">
                                    Assign New ArtId to their respective Tiers
                                    <div className="d-lg-flex d-none py-3">
                                        <div className="example">Example</div>
                                        <div className="description pl-3">
                                            <div>New Art ID 1 to Tier 1</div>
                                            <div>New Art ID 2 to 4 to Tier 2</div>
                                            <div>...</div>
                                            <div>New Art ID 68 to 71 to Tier 8</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-9 px-0">
                                <div className="d-flex align-items-center text-center h-100">
                                    <div className="col-3 h-100 px-0 px-lg-1">
                                        <div className="tire-panel d-flex align-items-center justify-content-center cornerround h-100 p-1 p-lg-3">Tire 1</div>
                                    </div>
                                    <div className="col-3 h-100 px-0 px-lg-1">
                                        <div className="tire-panel d-flex align-items-center justify-content-center cornerround h-100 p-1 p-lg-3">Tire 2</div>
                                    </div>
                                    <div className="col-3 h-100 px-0 px-lg-1">
                                        <div className="tire-panel d-flex align-items-center justify-content-center cornerround h-100 p-1 p-lg-3">...</div>
                                    </div>
                                    <div className="col-3 h-100 px-0 px-lg-1">
                                        <div className="tire-panel d-flex align-items-center justify-content-center cornerround h-100 p-1 p-lg-3">Tire 8</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 px-0 px-lg-1"></div>
                            <div className="col-9 px-0 px-lg-1">
                                <div className="d-flex align-items-end">
                                    <div className="col-3 text-center">
                                        <img className="img-arrow-down" src={arrowDown} alt="CryptoAmulets arrow" />
                                    </div>
                                    <div className="col-3 text-center">
                                        <img className="img-arrow-down" src={arrowDown} alt="CryptoAmulets arrow" />
                                    </div>
                                    <div className="col-3 text-center">
                                        <img className="img-arrow-down" src={arrowDown} alt="CryptoAmulets arrow" />
                                    </div>
                                    <div className="col-3 text-center">
                                        <img className="img-arrow-down" src={arrowDown} alt="CryptoAmulets arrow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3 px-0 px-lg-1">
                                <div className="distribution-1 cornerround h-100 p-2 p-lg-3">
                                    Final sorting of New ArtID to Token ID
                                </div>
                            </div>
                            <div className="col-9 px-0 px-lg-1">
                                <div className="distribution-42 cornerround h-100">
                                    <div className="d-flex align-items-center text-center">
                                        <div className="col-12">
                                            <div className="my-4 my-md-5 py-2 py-md-3">Sorting process within respective Tiers <br /> to finalize the New ArtID for respective Token ID</div>
                                        </div>
                                    </div>
                                </div>                                    
                            </div>
                        </div>
                    </div>
                </div> */}

                <div id="SmartContract" className="text-left py-5">    
                    <div className="container">    
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-12 px-0">
                                <figure className="position-relative cornerround FuturePlanImage">
                                    <img className="img-fluid cornerround w-100" src={futureplan} alt="Future" />
                                    <figcaption className="position-absolute d-flex justify-content-center align-items-center">
                                        <h2 className="sec-heading text-center">
                                            Verified Smart Contract
                                            
                                            <span className="d-block pt-3 contract-address heading-color3">0x0cB53474ac2939b5797Aaf397474d44EBa5872D9</span>
                                        </h2>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </article>
    )
}

export default About;