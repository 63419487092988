
const getPrice = (index) => {
  let price = 0.02;
  if (index >= 7900) {
      price = 0.8;
  } else if (index >= 7500) {
      price = 0.6;
  } else if (index >= 6500) {
      price = 0.45;
  } else if (index >= 5000) {
      price = 0.3;
  } else if (index >= 3000) {
      price = 0.16;
  } else if (index >= 1500) {
      price = 0.08;
  } else if (index >= 500) {
      price = 0.04;
  }
  return price;
}

export default getPrice;