import React from 'react';
import Web3 from 'web3';
import contractInfo from './../../amuletsContract.js';
import ListItem from '../../components/collections/ListItem';
import ListItem2 from '../../components/collections/ListItem2';
import ReactPaginate from 'react-paginate';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
// import * as anchor from '@project-serum/anchor';
// import { getConfig, getMetadata } from '../../phantomService.js';
import solana_info from '../../solanaContract.js';
import { PublicKey } from '@solana/web3.js';
import axios from 'axios';
import { getAmuletsMachine, solana_connection } from '../../phantomService.js';

class Gallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            perPage: 8,
            offset: 0,
            pageCount: 0,
            totalSupply: 0,
            baseURI: '',
            fullList: [],

            perPage2: 8,
            offset2: 0,
            pageCount2: 0,
            totalSupply2: 0,
            baseURI2: '',
            fullList2: [],

            selectedIndex: 0,
            s2_nft_list: solana_info.s2_nft_list,
            s2_is_loading: false
        };
        this.handleSelect = this.handleSelect.bind(this);

        
    }

    componentDidMount() {
        const provider = new Web3.providers.HttpProvider(contractInfo.infura_url);
        let web3 = new Web3(provider);
        const amuletsContract = new web3.eth.Contract(contractInfo.abi , contractInfo.contract_address);
        this.initialData(amuletsContract);

        this.initialDataS2();
    }

    async initialData(amuletsContract) {

        var totalSupply = await amuletsContract.methods.totalSupply().call();
        var baseURI = await amuletsContract.methods.baseURI().call();
        this.setState({totalSupply: totalSupply, baseURI: baseURI, pageCount: Math.ceil(totalSupply / this.state.perPage)});

        if (totalSupply < this.state.perPage) this.state.perPage = totalSupply;
        for(let i = 1; i <= this.state.perPage; i++) {
            fetch(baseURI + i)
            .then(res => res.json())
            .then((result) => {
                    result['index'] = i;
                    
                    this.setState(state => {
                        const fullList = state.fullList.push(result);
                        return fullList;
                    });
                },
                (error) => {
                    console.log(error);
                }
            )
        }
    }

    async fetchJson(file_index) {
        var url = solana_info.s2_base_uri + '/' + file_index + '.json';
        var resp = await axios.get(url);
        if ( resp.status === 200 ) {
            var result = resp.data;
            result['file_index'] = file_index;
            result['index'] = file_index;
            return result;
        }
        return null;
    }

    async initialDataS2() {
       
        this.setState({ s2_is_loading: true });
        
        var totalSupply2 = 1200;
        var baseURI2 = solana_info.s2_base_uri;
        this.setState({ totalSupply2: totalSupply2, baseURI2: baseURI2, pageCount2: Math.ceil(totalSupply2 / this.state.perPage2) });
            if (totalSupply2 > 0){
                for(let i = 0; i < this.state.perPage2; i++) {
                    var index = solana_info.s2_nft_list[i];
                    var result = await this.fetchJson(index);
                    if ( result != null ) {
                        result['owner'] = '';
                        this.setState(state => {
                            const fullList2 = state.fullList2.push(result);
                            return fullList2;
                        });
                    }
                }
            }
        this.setState({ s2_is_loading: false });

    }

    pageData() {
        for(let i = this.state.offset + 1; i <= this.state.offset + this.state.perPage; i++) {
            fetch(this.state.baseURI + i)
            .then(res => res.json())
            .then((result) => {
                    result['index'] = i;
                    this.setState(state => {
                        const fullList = state.fullList.push(result);
                        return fullList;
                    });
                },
                (error) => {
                    console.log(error);
                }
            )
        }
    }

    async pageData2() {
        for(let i = this.state.offset2; i < this.state.offset2 + this.state.perPage2; i++) {
            var index = this.state.s2_nft_list[i];

            if ( index !== undefined ) {
                var result = await this.fetchJson(index);
                if ( result !== null ) {
                    this.setState(state => {
                        const fullList2 = state.fullList2.push(result);
                        return fullList2;
                    });
                }
            }            
        }
    }

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage);
    
        this.setState({ offset: offset, fullList: [] }, () => {
          this.pageData();
        });
    };

    handlePageClick2 = (data2) => {
        let selected2 = data2.selected;
        let offset2 = Math.ceil(selected2 * this.state.perPage2);
// console.log(data2, selected2, offset2);    
        this.setState({ offset2: offset2, fullList2: [] }, () => {
          this.pageData2();
        });
    };
    
    handleSelect = index => {
        this.setState({ selectedIndex: index });
    }

    render() {
        const renderListItem = (item) => <ListItem item={item} key={item.index} />
        const renderListItem2 = (item) => <ListItem2 item={item} key={item.index} />
        const renderFullList = () => this.state.fullList.sort((a, b) => a.index > b.index ? 1 : -1).map(renderListItem);
        // const renderFullList2 = () => this.state.fullList2.sort((a, b) => a.index > b.index ? 1 : -1).map(renderListItem2);
        const renderFullList2 = () => this.state.fullList2.map(renderListItem2);

        return (
            <article>
                <section id="my-collection" className="gallery pt-2 pb-5">
                    <div className="container section-container position-relative">
                        <div className="row pb-3">
                            <div className="col-xl-7 col-lg-7 col-md-12 text-center text-lg-left pr-lg-1">
                                <div className="header-card cornerround h-100 p-3 p-lg-5">
                                    <h2 className="sec-heading text-lg-left">Gallery</h2>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-12 text-center pl-1 d-none d-lg-block">
                                <div className="header-card cornerround h-100 p-5 d-flex align-items-center justify-content-center">
                                    <h2 className="sec-heading text-center">
                                        {/* <span className="heading-color4">{this.state.selectedIndex ? 'Series 1' : 'Series 2'}</span>
                                        <br></br>
                                        <span className="heading-color4">{this.state.selectedIndex ? 'LP Heng' : 'LP Somask'}</span> */}
                                        <span className="heading-color4">{this.state.selectedIndex ? 'Series 2' : 'Series 1'}</span>
                                        <br></br>
                                        <span className="heading-color4">{this.state.selectedIndex ? 'LP Somask' : 'LP Heng'}</span>
                                    
                                    </h2>
                                </div>
                            </div>
                        </div>

                        <div className="pb-3">
                            <Tabs className="w-100" selectedIndex={this.state.selectedIndex} onSelect={this.handleSelect}>
                                <TabList>
                                    {/* <Tab>Series 2 — <span className="white">LP Somsak</span></Tab>
                                    <Tab>Series 1 — <span className="white">LP Heng</span></Tab> */}
                                    <Tab>Series 1 — <span className="white">LP Heng</span></Tab>
                                    <Tab>Series 2 — <span className="white">LP Somsak</span></Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="all-cards justify-content-start mb-5">
                                        {/* {renderFullList2()} */}
                                        {renderFullList()}
                                    </div>
                                    { this.state.pageCount > 1 ? (
                                        <div className="my-pagination mb-5">
                                            <ReactPaginate
                                                previousLabel={''}
                                                nextLabel=''
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={2}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={'pagination justify-content-center'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    ) : ( '' )}
                                </TabPanel>
                                <TabPanel>
                                    <div className="all-cards justify-content-start mb-5">
                                        {
                                            this.state.s2_is_loading ?
                                            (
                                            <div className="full-width text-center">
                                                <div className="minting-spinner">
                                                    <div className="minting-spinner-text">Loading</div>
                                                    <div className="minting-spinner-component red"></div>
                                                    <div className="minting-spinner-component green"></div>
                                                    <div className="minting-spinner-component blue"></div>
                                                </div>
                                            </div>
                                            )
                                            :
                                            renderFullList2()
                                        }
                                    </div>
                                    { this.state.pageCount2 > 1 ? (
                                        <div className="my-pagination">
                                            <ReactPaginate
                                                previousLabel={''}
                                                nextLabel=''
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={this.state.pageCount2}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={2}
                                                onPageChange={this.handlePageClick2}
                                                containerClassName={'pagination justify-content-center'}
                                                activeClassName={'active'}
                                            />
                                        </div>
                                    ) : ( '' )}

                                    {/* <h2 className="sec-heading text-center">
                                        Coming Soon on Solana
                                    </h2> */}
                                    
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div> 
                </section>
            </article>
        )
    }
}

export default Gallery;