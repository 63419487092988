import React from 'react';
// import { Accordion, Card } from 'react-bootstrap';
// import featured1 from './../../assets/images/img-section4-1.png';
// import featured2 from './../../assets/images/img-section4-2.png';
// import featured3 from './../../assets/images/img-section4-3.png';
// import featured4 from './../../assets/images/img-section4-4.png';
import connect from './../../assets/images/img-connect-wallet.png';
import funding from './../../assets/images/img-funding.png';

const FAQ = (props) => {
    return (
        <article>
            <section id="buy" className="text-center">
                <div className="float-img object1"></div>
                <div className="float-img object2"></div>
                <div className="container section-container position-relative">
                    <h2 className="sec-heading mb-4">How to buy CryptoAmulets</h2>

                    <p className="description mb-5">Great time ahead as you have decided to buy CryptoAmulets. We feel <br className="d-none d-lg-block" /> pleasure to welcome you to the pool of people who have chosen the way <br className="d-none d-lg-block" /> to be blessed both spiritually and ﬁnancially.</p>

                    <div id="gallery" className="mb-5">
                        {/* <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-md-0">
                                <div className="item-card">
                                    <img className="img-fluid" src={featured1} alt="Amulets Artwork Featured 1" />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-md-0">
                                <div className="item-card">
                                    <img className="img-fluid" src={featured2} alt="Amulets Artwork Featured 2" />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-md-0">
                                <div className="item-card">
                                    <img className="img-fluid" src={featured3} alt="Amulets Artwork Featured 3" />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4 mb-md-0">
                                <div className="item-card">
                                    <img className="img-fluid" src={featured4} alt="Amulets Artwork Featured 4" />
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <p className="description text-left mb-4">We will now guide you on how you can buy CryptoAmulets.</p>

                    <div className="buy-card">
                        <div className="text-left">
                            <div className="info-inner">
                                <h2 className="sec-heading mw-100 mb-2">Steps:</h2>
                                <ol className="mb-5 line-height-11 Fsize_16">
                                    <li className="pb-2">1. Install MetaMask wallet.</li>
                                    <li className="pb-2">2. Deposit a Ethereum (ETH) in the wallet with the amount of Ether you want to spend (including gas costs)</li>
                                    <li className="pb-2">3. Connect your MetaMask wallet with our website</li>
                                    <li className="pb-2">4. Select quantity of CryptoAmulets to buy and Click Buy</li>
                                </ol>

                                <h2 className="sec-heading mw-100 mb-2">Install MetaMask</h2>
                                <ol className="mb-5 line-height-11 Fsize_16">
                                    <li className="pb-2">1. To install MetaMask, please go here: <a className="font-weight-bold" target="_blank" rel="noreferrer" href="https://metamask.io/download">https://metamask.io/download</a>. It can be added to your <strong className="font-weight-bold">“exten- sions”</strong> within your browser. Follow the MetaMask instructions to set it up.</li>
                                    <li className="pb-2">2. Once your wallet is set up, and you have created your password and safely secured your seed phrase, you will be ready to fund your wallet.</li>
                                    <li className="pb-2">3. To learn more about how to get MetaMask set up, visit: <a className="font-weight-bold" target="_blank" rel="noreferrer" href="https://blog.wetrust.io/how-to-in-stall-and-use-metamask-7210720ca047">https://blog.wetrust.io/how-to-in-stall-and-use-metamask-7210720ca047</a></li>
                                </ol>

                                <h2 className="sec-heading mw-100 mb-2">Funding your wallet with ETH</h2>
                                <p className="mb-5">You can buy ETH from crypto currency exchanges like Bitkrub, Binance, Gemini, etc and deposit ETH to your MetaMask wallet address. Alternatively, if you don’t have access to crypto exchanges, you can use Wyre as it allows you to use a debit or credit card to buy and deposit ETH right in to your MetaMask wallet.</p>

                                <p className="font-weight-bold mb-2">Wyre:</p>
                                <img className="img-fluid mb-5" src={funding} alt="ETH funding screen" />

                                <h2 className="sec-heading mw-100 mb-2">Connecting your wallet to CryptoAmulets.io</h2>
                                <ol className="mb-5 line-height-11 Fsize_16">
                                    <li className="pb-2">1. After you have ETH in your MetaMask wallet, you are ready to purchase an amulet.</li>
                                    <li className="pb-2">2. Go to <a className="font-weight-bold" href="https://cryptoAmulets.io">CryptoAmulets.io</a></li>
                                    <li className="pb-2">3. Click the <strong className="font-weight-bold">“Connect”</strong> button on the top right corner of the website. Your wallet will open for connection. (If the connect button does not work, you may need to login to your MetaMask wallet in the web browser and retry again).</li>
                                    <li className="pb-2">4. Once connected, you will see a green radio button with the word “Connected” and you are now ready to buy.</li>
                                </ol>
                                <p className="text-center"><img className="img-fluid mb-5" src={connect} alt="Wallet connect screen" /></p>

                                <h2 className="sec-heading mw-100 mb-2">Select quantity of CryptoAmulets to buy and Click Buy</h2>
                                <ol className="mb-5 line-height-11 Fsize_16">
                                    <li className="pb-2">1. Click <strong className="font-weight-bold">“Buy”</strong> on the navigation bar on the top of the website. This will lead you to the Purchase section. </li>
                                    <li className="pb-2">2. Enter the quantity of CryptoAmulets you wish to buy and the total price will be shown within the <strong className="font-weight-bold">“Buy”</strong> button</li>
                                    <li className="pb-2">3. Click the <strong className="font-weight-bold">“Buy X NFTs for X.XX ETH”</strong> and your connected MetaMask wallet will open up for you to “Confirm” the purchase</li>
                                    <li className="pb-2">4. Click <strong className="font-weight-bold">“Conﬁrm”</strong>.</li>
                                    <li className="pb-2">5. Once the transaction is finalized the amulet will show up in your wallet and you can view it under “My Collections”</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section id="faq" className="text-center py-5">
                <div className="container section-container position-relative">
                    <h2 className="sec-heading mb-4">frequently asked questions</h2>
                    <p className="description mb-5">Everything you need to know about the largest marketplace of crypto collectables</p>
                    <form id="faqSearch">
                        <div className="form-group form-check p-0 m-0">
                            <input type="email" className="w-100 my-input-with-icon" id="searchFaq" placeholder="Search FAQ" required />
                            <label className="sr-only" for="searchFaq">FAQ search</label>
                            <span className="icon magnifier"></span>
                        </div>
                    </form>
                    <Accordion id="faqAccordion">
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <span className="question">A computer running Chrome or Firefox</span>
                                        <psan className="plus-icon"></psan>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <h5 className="heading">How do I get a ANFT?</h5>
                                    <p>Nonfungible, meaning you can’t exchange it for another thing of equal value. A $10 bill can be exchanged for two $5 bills. One bar of gold can be swapped for another bar of gold of the same size. Those things are fungible. An NFT, though, is one of a kind.</p>
                                    <p>The token refers to a unit of currency on the blockchain. It’s how cryptocurrency like Bitcoin is bought and sold.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <span className="question">I can’t access my wallet. Can you recover my Amulets?</span>
                                        <psan className="plus-icon"></psan>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <h5 className="heading">How do I get a ANFT?</h5>
                                    <p>Nonfungible, meaning you can’t exchange it for another thing of equal value. A $10 bill can be exchanged for two $5 bills. One bar of gold can be swapped for another bar of gold of the same size. Those things are fungible. An NFT, though, is one of a kind.</p>
                                    <p>The token refers to a unit of currency on the blockchain. It’s how cryptocurrency like Bitcoin is bought and sold.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <span className="question">Where does my ether go when I place an offer?</span>
                                        <psan className="plus-icon"></psan>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    <h5 className="heading">How do I get a ANFT?</h5>
                                    <p>Nonfungible, meaning you can’t exchange it for another thing of equal value. A $10 bill can be exchanged for two $5 bills. One bar of gold can be swapped for another bar of gold of the same size. Those things are fungible. An NFT, though, is one of a kind.</p>
                                    <p>The token refers to a unit of currency on the blockchain. It’s how cryptocurrency like Bitcoin is bought and sold.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <span className="question">What are the advantages of NFTs?⁣⁣</span>
                                        <psan className="plus-icon"></psan>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    <h5 className="heading">How do I get a ANFT?</h5>
                                    <p>Nonfungible, meaning you can’t exchange it for another thing of equal value. A $10 bill can be exchanged for two $5 bills. One bar of gold can be swapped for another bar of gold of the same size. Those things are fungible. An NFT, though, is one of a kind.</p>
                                    <p>The token refers to a unit of currency on the blockchain. It’s how cryptocurrency like Bitcoin is bought and sold.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <span className="question">Can I log into my account from different devices?</span>
                                        <psan className="plus-icon"></psan>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    <h5 className="heading">How do I get a ANFT?</h5>
                                    <p>Nonfungible, meaning you can’t exchange it for another thing of equal value. A $10 bill can be exchanged for two $5 bills. One bar of gold can be swapped for another bar of gold of the same size. Those things are fungible. An NFT, though, is one of a kind.</p>
                                    <p>The token refers to a unit of currency on the blockchain. It’s how cryptocurrency like Bitcoin is bought and sold.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="5">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <span className="question">How do I get my first ANFT?</span>
                                        <psan className="plus-icon"></psan>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    <h5 className="heading">How do I get a ANFT?</h5>
                                    <p>Nonfungible, meaning you can’t exchange it for another thing of equal value. A $10 bill can be exchanged for two $5 bills. One bar of gold can be swapped for another bar of gold of the same size. Those things are fungible. An NFT, though, is one of a kind.</p>
                                    <p>The token refers to a unit of currency on the blockchain. It’s how cryptocurrency like Bitcoin is bought and sold.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="6">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <span className="question">How do I sell a ANFT?</span>
                                        <psan className="plus-icon"></psan>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                    <h5 className="heading">How do I get a ANFT?</h5>
                                    <p>Nonfungible, meaning you can’t exchange it for another thing of equal value. A $10 bill can be exchanged for two $5 bills. One bar of gold can be swapped for another bar of gold of the same size. Those things are fungible. An NFT, though, is one of a kind.</p>
                                    <p>The token refers to a unit of currency on the blockchain. It’s how cryptocurrency like Bitcoin is bought and sold.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="7">
                                <h2 className="mb-0">
                                    <button className="btn btn-link btn-block text-left" type="button">
                                        <span className="question">I lost access to my wallet. Can I recover my ANFT?</span>
                                        <psan className="plus-icon"></psan>
                                    </button>
                                </h2>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                    <h5 className="heading">How do I get a ANFT?</h5>
                                    <p>Nonfungible, meaning you can’t exchange it for another thing of equal value. A $10 bill can be exchanged for two $5 bills. One bar of gold can be swapped for another bar of gold of the same size. Those things are fungible. An NFT, though, is one of a kind.</p>
                                    <p>The token refers to a unit of currency on the blockchain. It’s how cryptocurrency like Bitcoin is bought and sold.</p>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </div>
            </section> */}
        </article>
    )
}

export default FAQ;