import React, { useState, useEffect } from 'react';
import { Navbar, NavDropdown, Dropdown } from 'react-bootstrap';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import logo from './../assets/images/amulets-logo-white.svg';
import connect from './../assets/images/connect.svg';
import Web3 from 'web3'

// import { Connection, SystemProgram, clusterApiUrl, PublicKey } from '@solana/web3.js';
// import solana_info from '../solanaContract';
// import { Program, Provider, web3 } from '@project-serum/anchor';

// import { getPhantomWallet } from '@solana/wallet-adapter-wallets';
import { isPhantomInstalled } from '../phantomService';
// import { WalletMultiButton, WalletDisconnectbutton } from '@solana/wallet-adapter-react-ui';

const Header = (props) => {

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    // const [account, setAccount] = useState(null);
    let [web3, setWeb3] = useState(null);
    const [wallet_connected, setWalletConnected] = useState(0);
    const [account_mm, setAccountMM] = useState(null);
    const [account_pt, setAccountPT] = useState(null);

    useEffect(() => {
        setTimeout(async ()=>{
            if ( window.ethereum ) {
                let web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();
                if ( accounts.length > 0 ) checkAccountMM();
            }
            if (window.solana.publicKey != null) checkAccountPT();
        }, 1000);        
    }, []);

    // invoke to connect to wallet account
    async function connectMmWallet() {
        // Modern DApp Browsers
        if (window.ethereum) {
            try { 
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                checkAccountMM();
            } catch(e) {
                // User has denied account access to DApp...
            }
        }
        // Legacy DApp Browsers
        else if (window.web3) {
            web3 = new Web3(window.web3.currentProvider);
        }
        // Non-DApp Browsers
        else {
            alert('Please install MetaMask to connect with website');
        }
    }

    async function disconnectMmWallet() {
        if (window.ethereum) {
            try {
                await window.ethereum.request({
                    method: "eth_requestAccounts",
                    params: [{eth_accounts: {} }]
                });
                // setAccount(null);
                setAccountMM(null);
            } catch(e) {

            }
        } else {
            console.log('MetaMask no installed');
        }
    }

    // invoke to check if account is already connected
    async function checkAccountMM() {
        if (window.ethereum) {
            let web3 = new Web3(window.ethereum);
            setWeb3(web3);
            const accounts = await web3.eth.getAccounts();
            // console.log(accounts);
            // setAccount(accounts[0]);
            setAccountMM(accounts[0]);
            var wallet_connected_ = wallet_connected < 2 ? (wallet_connected+1) : wallet_connected;
            setWalletConnected(wallet_connected_);
        }
    }

    async function checkAccountPT() {
        try {
            // console.log('---------------------------------------------')
            // console.log(window.solana);
            // console.log('---------------------------------------------')
            if (isPhantomInstalled()) {
                // if (window.solana.publicKey===null) return;
                const phantom_resp = await window.solana.connect();
                setAccountPT(phantom_resp.publicKey.toString());
            }
            else {
                setAccountPT(null);
                // alert('Phantom wallet not installed on browser')
            }
        } catch (error) {
            console.log(error);
        }
    }

    function onToggleClick() {
        var nav = document.getElementById("navbarMain");
        var toggle = document.getElementById("navbarToggle");
        if ( !isNavCollapsed ) {
            setTimeout(function() {
                nav.classList.add("show");
                toggle.classList.remove("collapsed");
            }, 400);
        }
        setIsNavCollapsed(true);
    }

    function onNavClick() {
        var nav = document.getElementById("navbarMain");
        var toggle = document.getElementById("navbarToggle");
        if ( isNavCollapsed ) {
            nav.classList.remove("show");
            toggle.classList.add("collapsed");
        }
        setIsNavCollapsed(false);
    }

    const connectPtWallet = async () => {
        try {
            const phantom_resp = await window.solana.connect();
            setAccountPT(phantom_resp.publicKey.toString());
        } catch (error) {
            console.log(error);
        }
    }

    async function disconnectPtWallet() {
        try {
            if (window.solana) {
                // if (window.solana.publicKey===null) return;
                await window.solana.disconnect();
                setAccountPT(null);
            }
            
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Navbar className="navbar main-navbar" bg="dark" variant="dark" expand="lg">
            <div className="container">
                <div className="nav-inner-wrapper">
                    <div className="mr-auto logo">
                        <Navbar.Brand href="/" className="navbar-brand"><img className="img-fluid" src={logo} alt="Amulets Logo" /></Navbar.Brand>
                    </div>
                    <Navbar.Toggle id="navbarToggle" aria-controls="navbarMain" onClick={onToggleClick} />
                    <Navbar.Collapse id="navbarMain">
                        <ul className="d-flex justify-content-between align-items-center w-100">
                            <li>
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <NavDropdown title="About" id="nav-dropdown">
                                            <NavDropdown.Item eventKey="4.1"><NavLink className="nav-link" exact to="/about-2" onClick={onNavClick}>Series 2 (LP Somsak)</NavLink></NavDropdown.Item>
                                            <NavDropdown.Item eventKey="4.2"><NavLink className="nav-link" exact to="/about" onClick={onNavClick}>Series 1 (LP Heng)</NavLink></NavDropdown.Item>
                                        </NavDropdown>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" exact to="/faq" onClick={onNavClick}>FAQ</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" exact to="/gallery" onClick={onNavClick}>Gallery</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <ul className="navbar-nav align-items-center">
                                    <li className="nav-item dropdown">
                                        <Dropdown className="btn my-btn1" style={{ padding: '10px 16px'}}>
                                            <Dropdown.Toggle id="navbarDropdown2" className="nav-link dropdown-toggle user m-auto">
                                                {
                                                    (account_mm == null && account_pt == null) ? 
                                                    (<><span>Connect Wallet </span><span><img src={connect} alt="Amulets connect" className="connect-icon" /></span></>)
                                                    :
                                                    (
                                                    <>
                                                        <div className="wallet-connected-wapper">
                                                            { account_mm ? 
                                                                (<div style={{ display: 'flex' }}><span className="icon metamask-icon"></span><span className="wallet-connected-status on"></span></div>)
                                                                : 
                                                                (<div style={{ display: 'flex' }}><span className="icon metamask-icon"></span><span className="wallet-connected-status off"></span></div>) 
                                                            } 
                                                            { account_pt ? 
                                                                (<div style={{ display: 'flex' }}><span className="icon phantom-icon"></span><span className="wallet-connected-status on"></span></div>)
                                                                : 
                                                                (<div style={{ display: 'flex' }}><span className="icon phantom-icon"></span><span className="wallet-connected-status off"></span></div>) 
                                                            }
                                                        </div>
                                                    </>
                                                    )
                                                }
                                                
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right s2-bg">
                                                <Dropdown.Item className="dropdown-item" onClick={connectMmWallet}>
                                                    <span className="icon metamask-icon"></span>
                                                    {account_mm ? (
                                                        <>{ account_mm.substr(0, 4) + '....' + account_mm.substr(-4, 4) }</>
                                                    ) : (
                                                        <>Metamask(S1)</>
                                                    )}
                                                </Dropdown.Item>
                                                <Dropdown.Item className="dropdown-item" onClick={connectPtWallet}>
                                                    <span className="icon phantom-icon"></span> 
                                                    {account_pt ? (
                                                        <>{account_pt.substr(0, 4) + '....' + account_pt.substr(-4, 4) }</>
                                                    ) : (
                                                        <>Phantom(S2)</>
                                                    )}
                                                    
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Dropdown>
                                            <Dropdown.Toggle id="navbarDropdown2" className="nav-link dropdown-toggle user m-auto">
                                                <span className="user-icon"></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                <Dropdown.Item className="dropdown-item">
                                                    <NavLink className="nav-link" exact to="/collections" onClick={onNavClick}>
                                                        <span className="icon collection-icon"></span> My Collection
                                                    </NavLink>
                                                </Dropdown.Item>
                                                {account_mm ? (
                                                    <>
                                                        <Dropdown.Item className="dropdown-item" onClick={disconnectMmWallet}><span className="icon transaction-icon"></span> Logout(S1)</Dropdown.Item>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Dropdown.Item className="dropdown-item" onClick={connectMmWallet}><span className="icon transaction-icon"></span> Login(S1)</Dropdown.Item>
                                                    </>
                                                )}
                                                {account_pt ? (
                                                    <>
                                                        <Dropdown.Item className="dropdown-item" onClick={disconnectPtWallet}><span className="icon transaction-icon"></span> Logout(S2)</Dropdown.Item>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Dropdown.Item className="dropdown-item" onClick={connectPtWallet}><span className="icon transaction-icon"></span> Login(S2)</Dropdown.Item>
                                                    </>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </Navbar.Collapse>
                </div>    
            </div>
        </Navbar>
    )
}

export default Header;