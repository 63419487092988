import * as anchor from '@project-serum/anchor';
import { MintLayout, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';
import solana_info from './solanaContract';

export const CANDY_MACHINE_PROGRAM = new anchor.web3.PublicKey(solana_info.candy_machine);
export const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey(solana_info.metadata);
export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new anchor.web3.PublicKey(solana_info.spl_token);

export const isPhantomInstalled = () => { return window.solana && window.solana.isPhantom; }

export const getProvider = () => {
  if ( "solana" in window ) {
    const provider = window.solana;
    if (provider.isPhantom) return provider;
  }
  window.open("https://phantom.app/", "_blank");
};

export const getAmuletsMachine = async (anchorWallet, amuletsMachineId, connection) => {
  const provider = new anchor.Provider(connection, anchorWallet, { preflightCommitment: 'recent' });

  const idl = await anchor.Program.fetchIdl( CANDY_MACHINE_PROGRAM, provider );

  const program = new anchor.Program(idl, CANDY_MACHINE_PROGRAM, provider);
  const amuletsMachine = { id: amuletsMachineId, connection, program };
  const state = await program.account.candyMachine.fetch(amuletsMachineId);

  const itemsAvailable = state.data.itemsAvailable.toNumber();
  const itemsRedeemed = state.itemsRedeemed.toNumber();
  const itemsRemaining = itemsAvailable - itemsRedeemed;

  let goLiveDate = state.data.goLiveDate.toNumber();
  goLiveDate = new Date(goLiveDate*1000);

  const config_state = await program.account.config.fetch(new anchor.web3.PublicKey(solana_info.s2_machine_config));

  // const config_line = await program.transaction.getConfigLines(new anchor.web3.PublicKey(amuletsMachineId), new anchor.BN(0));

  return {
    amuletsMachine,
    itemsAvailable,
    itemsRedeemed,
    itemsRemaining,
    goLiveDate,
    state,
    config_state,
    idl
  }
}

export const mintOneToken = async ( amuletsMachine, config, payer, treasury ) => {
  const mint = anchor.web3.Keypair.generate();
  const token = await getTokenWallet(payer, mint.publicKey);
  const { connection, program } = amuletsMachine;
  const metadata = await getMetadata(mint.publicKey);
  const masterEdition = await getMasterEdition(mint.publicKey);

  const rent = await connection.getMinimumBalanceForRentExemption(MintLayout.span);

  return await program.rpc.mintNft({
    accounts: {
      config,
      candyMachine: amuletsMachine.id,
      payer: payer,
      wallet: treasury,
      mint: mint.publicKey,
      metadata,
      masterEdition,
      mintAuthority: payer,
      updateAuthority: payer,
      tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
      tokenProgram: TOKEN_PROGRAM_ID,
      systemProgram: anchor.web3.SystemProgram.programId,
      rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      clock: anchor.web3.SYSVAR_CLOCK_PUBKEY
    },
    signers: [mint],
    instructions: [
      anchor.web3.SystemProgram.createAccount({
        fromPubkey: payer,
        newAccountPubkey: mint.publicKey,
        space: MintLayout.span,
        lamports: rent,
        programId: TOKEN_PROGRAM_ID
      }),
      Token.createInitMintInstruction(TOKEN_PROGRAM_ID, mint.publicKey, 0, payer, payer),
      createAssociatedTokenAccountInstruction(token, payer, payer, mint.publicKey),
      Token.createMintToInstruction(TOKEN_PROGRAM_ID, mint.publicKey, token, payer, [], 1)
    ]
  });
}

export const awaitTransactionSignatureConfirmation = async (txid, timeout, connection, commitment, queryStatus) => {
  let done = false;
  let status = { slot: 0, confirmations: 0, err: null };
  let subId = 0;
  status = await new Promise(async (resolve, reject) => {
    setTimeout(() => {
      if (done) {
        return;
      }
      done = true;
      console.log("Rejecting for timeout...");
      reject({ timeout: true });
    }, timeout);
    try {
      subId = connection.onSignature(
        txid,
        (result, context) => {
          done = true;
          status = {
            err: result.err,
            slot: context.slot,
            confirmations: 0,
          };
          if (result.err) {
            console.log("Rejected via websocket", result.err);
            reject(status);
          } else {
            console.log("Resolved via websocket", result);
            resolve(status);
          }
        },
        commitment
      );
    } catch (e) {
      done = true;
      console.error("WS error in setup", txid, e);
    }
    while (!done && queryStatus) {
      // eslint-disable-next-line no-loop-func
      (async () => {
        try {
          const signatureStatuses = await connection.getSignatureStatuses([
            txid,
          ]);
          status = signatureStatuses && signatureStatuses.value[0];
          if (!done) {
            if (!status) {
              console.log("REST null result for", txid, status);
            } else if (status.err) {
              console.log("REST error for", txid, status);
              done = true;
              reject(status.err);
            } else if (!status.confirmations) {
              console.log("REST no confirmations for", txid, status);
            } else {
              console.log("REST confirmation for", txid, status);
              done = true;
              resolve(status);
            }
          }
        } catch (e) {
          if (!done) {
            console.log("REST connection error: txid", txid, e);
          }
        }
      })();
      
    }
  });

  //@ts-ignore
  if (connection._signatureSubscriptions[subId]) {
    connection.removeSignatureListener(subId);
  }
  done = true;
  console.log("Returning status", status);
  return status;
}

const getTokenWallet = async ( wallet, mint ) => {
  return (await anchor.web3.PublicKey.findProgramAddress([wallet.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()], SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID))[0];
}

export const getConfig = async ( authority, uuid ) => {
  return await anchor.web3.PublicKey.findProgramAddress([Buffer.from(solana_info.candy_machine_prefix), authority.toBuffer(), Buffer.from(uuid)], CANDY_MACHINE_PROGRAM)
}

export const getMetadata = async ( mint ) => {
  return ( await anchor.web3.PublicKey.findProgramAddress([ Buffer.from('metadata'), TOKEN_METADATA_PROGRAM_ID.toBuffer(), mint.toBuffer() ], TOKEN_METADATA_PROGRAM_ID) )[0];
}

export const getMasterEdition = async (mint) => {
  return (
    await anchor.web3.PublicKey.findProgramAddress([
      Buffer.from('metadata'),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
      Buffer.from('edition')
    ], 
      TOKEN_METADATA_PROGRAM_ID)
  )[0];
}

export const createAssociatedTokenAccountInstruction = ( associatedTokenAddress, payer, walletAddress, splTokenMintAddress ) => {
  const keys = [
    { pubkey: payer, isSigner: true, isWritable: true },
    { pubkey: associatedTokenAddress, isSigner: false, isWritable: true },
    { pubkey: walletAddress, isSigner: false, isWritable: false },
    { pubkey: splTokenMintAddress, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new anchor.web3.TransactionInstruction({
    keys,
    programId: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    data: Buffer.from([]),
  });
}

export const solana_connection = new anchor.web3.Connection(solana_info.rpc_host);