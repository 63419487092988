import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import Web3 from 'web3';
import contractInfo from './../../amuletsContract.js';
import ListItem from '../../components/collections/ListItem';
import ListItem2 from '../../components/collections/ListItem2';
import ReactPaginate from 'react-paginate';
import hopinIcon from './../../assets/images/hopin-icon.svg';
import amuletIcon from './../../assets/images/amulet-transparent.svg';
import plusIcon from './../../assets/images/collection-plus.svg';

// import { PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
// import { awaitTransactionSignatureConfirmation, getAmuletsMachine, mintOneToken, solana_connection } from '../../phantomService.js';
import solana_info from '../../solanaContract.js';
import axios from 'axios';

class Collections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            perPage: 8,
            offset: 0,
            pageCount: 0,
            tokensOfOwner: [],
            baseURI: '',
            fullList: [],

            perPage2: 8,
            offset2: 0,
            pageCount2: 0,
            tokensOfOwner2: [],
            baseURI2: '',
            fullList2: [],

            searchTerm: '',
            isLoading: false,
        };
    }

    componentDidMount() {
        this.initialData();
        this.fetchSolanaInfo();

        if ( window.solana ) {
            window.solana.on('disconnect', ()=>{
                this.setState({ fullList2: [], pageCount2: 0 });
            });
            window.solana.on('connect', ()=>{
                this.fetchSolanaInfo();
            });
        }
    }

    async initialData() {
        let web3;
        if (window.ethereum) {
            try { 
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                web3 = new Web3(window.ethereum);
            } catch(e) {
                console.log(e);
            }
        } else if (window.web3) {
            web3 = new Web3(window.web3.currentProvider);
        }

        if (typeof web3 != "undefined") {
            const amuletsContract = new web3.eth.Contract(contractInfo.abi , contractInfo.contract_address);
            const netId = await web3.eth.net.getId();
            const accounts = await web3.eth.getAccounts();
            if (netId === contractInfo.network_id) {
                var tokensOfOwner = await amuletsContract.methods.tokensOfOwner(accounts[0]).call();
                var baseURI = await amuletsContract.methods.baseURI().call();
                this.setState({ tokensOfOwner: tokensOfOwner, baseURI: baseURI})

                for(let token of tokensOfOwner.slice(this.state.offset, this.state.offset + this.state.perPage)) {            
                    fetch(baseURI + token)
                    .then(res => res.json())
                    .then((result) => { 
                            result['index'] = token;
                            this.setState(state => {
                                const fullList = state.fullList.push(result);
                                return fullList;
                            });
                            this.setState({pageCount: Math.ceil(tokensOfOwner.length / this.state.perPage)});
                        },
                        (error) => {
                            console.log(error);
                        }
                    )
                }
            } else {
                alert('Please select Ethereum Mainnet');
            }
        }
    }

    pageData() {      
        for(const id of this.state.tokensOfOwner.slice(this.state.offset, this.state.offset + this.state.perPage)) {            
             fetch(this.state.baseURI + id)
            .then(res => res.json())
            .then((result) => { 
                result['index'] = id;
                    this.setState(state => {
                        const fullList = state.fullList.push(result);
                        return fullList;
                    });
                },
                (error) => {
                    console.log(error);
                }
            )
        }     
    }

    isCryptoAmulets(creators_arr) {
        if ( creators_arr.length > 0 ) {
            for(var i=0;i<creators_arr.length;i++ ) {
                if ( creators_arr[i].address.toLowerCase() === solana_info.s2_machine_id.toLowerCase() ) return true;
            }
        }        
        return false;
    }

    async fetchSolanaInfo() {
        if (window.solana) {
            if (window.solana.publicKey != null) {
                this.setState({isLoading: true});
                var uri = `https://api${solana_info.network === 'devnet' ? '-devnet' : ''}.solscan.io`;
                var url = `${uri}/account/tokens`;
                var resp = await axios.get(url, { params: { price: 1, address: window.solana.publicKey.toBase58() } })
                var nftList = [];
                var tokenList = resp.data.data;
                if ( tokenList.length > 0 ) {
                    for(let i=0;i<tokenList.length;i++) {
                        var tokenAddress = tokenList[i].tokenAddress;
                        var token_url = `${uri}/account`;
                        var tokenInfoResp = await axios.get(token_url, { params: { address: tokenAddress } });
                        if ( tokenInfoResp.status === 200 ) {
                            var tokenInfo = tokenInfoResp.data.data;
                            var creators = tokenInfo.metadata.data.creators;
                            if (this.isCryptoAmulets(creators)) nftList.push(tokenInfo.metadata.data.name.split('#')[1]);
                        }
                    }
                    this.setState({ tokensOfOwner2: nftList, baseURI2: solana_info.s2_base_uri});
                    for(let token of nftList.slice(this.state.offset2, this.state.offset2 + this.state.perPage2)) {
                        var jsonResp = await axios.get(solana_info.s2_base_uri + '/' + token + '.json');
                        var jsonContent = jsonResp.data;
                        jsonContent['index'] = token;
                        this.setState(state => {
                            const fullList2 = state.fullList2.push(jsonContent);
                            return fullList2;
                        });
                        this.setState({pageCount2: Math.ceil(nftList.length / this.state.perPage2)});
                    }
                    this.setState({isLoading: false});
                }
            }
        }
    }

    pageData2() {      
        for(const id2 of this.state.tokensOfOwner2.slice(this.state.offset2, this.state.offset2 + this.state.perPage2)) {            
            fetch(this.state.baseURI2 + '/' + id2 + '.json')
            .then(res => res.json())
            .then((result) => { 
                    result['index'] = id2;
                    this.setState(state => {
                        const fullList2 = state.fullList2.push(result);
                        return fullList2;
                    });
                },
                (error) => {
                    console.log(error);
                }
            )
        }      
    }

    handlePageClick = (data) => {
        let selected = data.selected;
        let offset = Math.ceil(selected * this.state.perPage);
    
        this.setState({ offset: offset, fullList: [] }, () => {
            this.pageData();
        });
    };

    handlePageClick2 = (data) => {
        let selected2 = data.selected;
        let offset2 = Math.ceil(selected2 * this.state.perPage2);
    
        this.setState({ offset2: offset2, fullList2: [] }, () => {
            this.pageData2();
        });
    };

    render() {
        const renderListItem = (item) => <ListItem item={item} key={item.index}/>
        const renderListItem2 = (item) => <ListItem2 item={item} key={item.index}/>
        const renderList = () => this.state.fullList.map(renderListItem);
        const renderList2 = () => this.state.fullList2.map(renderListItem2);

        return (
            <article>
                <section id="my-collection" className="collection text-center pt-2 pb-5">
                    <div className="container section-container position-relative">
                        <div className="row pb-3 pb-lg-4">
                            <div className="col-xl-7 col-lg-7 col-md-12 text-center text-lg-left pr-lg-1">
                                <div className="header-card cornerround h-100 p-3 p-lg-5">
                                    <h2 className="sec-heading mb-2 mb-lg-4 text-lg-left">My Collection</h2>
                                    <a href="/#pricing" className="btn my-btn1 px-5">Buy <span><img src={hopinIcon} alt="arrow icon" className="arrow-buy" /></span></a>
                                    <img src={amuletIcon} alt="Amulets Collection" className="collection-header-icon d-block d-lg-none w-25 m-auto pt-3" />
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-12 text-center pl-1 d-none d-lg-block">
                                <div className="header-card cornerround h-100 p-5">
                                    <img src={amuletIcon} alt="Amulets Collection" className="collection-header-icon" />
                                </div>
                            </div>
                        </div>
                        
                        <div className="series2-collection p-0 p-lg-5 mt-4 mt-lg-0">
                            <h4 className="sec-sub-heading mb-4 text-left underline">Series 2 - LP Somsak</h4>
                            { this.state.fullList2.length > 0 ? (
                                <div className="all-cards justify-content-start">
                                    {
                                        this.state.isLoading ?
                                        (
                                        <div className="full-width text-center">
                                            <div className="minting-spinner">
                                                <div className="minting-spinner-text">Loading</div>
                                                <div className="minting-spinner-component red"></div>
                                                <div className="minting-spinner-component green"></div>
                                                <div className="minting-spinner-component blue"></div>
                                            </div>
                                        </div>
                                        )
                                        :
                                        renderList2()
                                    }
                                </div>
                            ) : (
                                <div className="all-cards justify-content-start">
                                    <div className="col-6 col-lg-3 mb-2 mb-lg-3 px-1 collection-buy-now">
                                        <NavLink className="item-card" exact to="/#pricing">
                                            <div className="bg-color">
                                                <img src={plusIcon} alt="Amulets Collection Plus" className="collection-plus-icon" />
                                            </div>
                                            <div className="detail-box">
                                                <div className="text-capitalize card-name">Buy</div>
                                                <div className="text-capitalize card-name">Series 2 — LP Somsak</div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            )}
                            { this.state.pageCount2 > 1 ? (
                                <div className="my-pagination">
                                    <ReactPaginate
                                        previousLabel={''}
                                        nextLabel=''
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.pageCount2}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick2}
                                        containerClassName={'pagination justify-content-center'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            ) : ( '' )}
                        </div>

                        <div className="series1-collection p-0 p-lg-5 mt-4 mt-lg-0 mb-5">
                            <h4 className="sec-sub-heading mb-4 text-left underline">Series 1 - LP Heng</h4>
                            { this.state.fullList.length > 0 ? (
                                <div className="all-cards justify-content-start">
                                    {renderList()}
                                </div>
                            ) : (
                                <div className="all-cards justify-content-start">
                                    <div className="col-6 col-lg-3 mb-2 mb-lg-3 px-1 collection-buy-now">
                                        <NavLink className="item-card" exact to="/#pricing">
                                            <div className="bg-color">
                                                <img src={plusIcon} alt="Amulets Collection Plus" className="collection-plus-icon" />
                                            </div>
                                            <div className="detail-box">
                                                <div className="text-capitalize card-name">Buy</div>
                                                <div className="text-capitalize card-name">Series 1 — LP Heng</div>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            )}
                            { this.state.pageCount > 1 ? (
                                <div className="my-pagination">
                                    <ReactPaginate
                                        previousLabel={''}
                                        nextLabel=''
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick}
                                        containerClassName={'pagination justify-content-center'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            ) : ( '' )}
                        </div>
                    </div>
                </section>
            </article>
        )
    }
}

export default Collections;