import React from 'react';
import Web3 from 'web3';
import contractInfo from './../../amuletsContract.js';
import getPrice from '../../libs/GetPrice';
import ListItem from '../../components/collections/ListItem';
import ReactPaginate from 'react-paginate';

class Vault extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            baseURI: '',
            perPage: 8,
            offsetB: 0,
            pageCountB: window.Configs.bought.length / 8,
            boughtList: [],
        };
    }

    componentDidMount() {
        const provider = new Web3.providers.HttpProvider(contractInfo.infura_url);
        let web3 = new Web3(provider);
        const amuletsContract = new web3.eth.Contract(contractInfo.abi , contractInfo.contract_address);
        
        this.initialData(amuletsContract);
    }

    async initialData(amuletsContract) {

        var baseURI = await amuletsContract.methods.baseURI().call();
        this.setState({ baseURI: baseURI });

        for(const id of window.Configs.bought.slice(this.state.offsetB, this.state.offsetB + this.state.perPage)) {            
            fetch(baseURI + id)
            .then(res => res.json())
            .then((result) => { 
                    result['index'] = id;
                    result['price'] = getPrice(id);
                    this.setState(state => {
                        const boughtList = state.boughtList.push(result);
                        return boughtList;
                    });
                },
                (error) => {
                    console.log(error);
                }
            )
        }
    }

    pageDataB() {
        for(const id of window.Configs.bought.slice(this.state.offsetB, this.state.offsetB + this.state.perPage)) {            
            fetch(this.state.baseURI + id)
            .then(res => res.json())
            .then((result) => { 
                    result['index'] = id;
                    result['price'] = getPrice(id);
                    this.setState(state => {
                        const boughtList = state.boughtList.push(result);
                        return boughtList;
                    });
                },
                (error) => {
                    console.log(error);
                }
            )
        }
    }

    handleBoughtPageClick = (data) => {
        let selected = data.selected;
        let offsetB = Math.ceil(selected * this.state.perPage);
    
        this.setState({ offsetB: offsetB, boughtList: [] }, () => {
          this.pageDataB();
        });
    };

    render() {
        const renderListItem = (item) => <ListItem item={item} key={item.index}/>
        const renderBoughtList = () => this.state.boughtList.map(renderListItem);

        return (
            <article>
                <section id="my-collection" className="vault text-center pt-2 pb-5">
                    <div className="pb-5">
                        <div className="container section-container position-relative">
                            <div className="row pb-3">
                                <div className="col-xl-7 col-lg-7 col-md-12 text-center text-lg-left pr-lg-1">
                                    <div className="header-card cornerround h-100 p-3 p-lg-5">
                                        <h2 className="sec-heading sec-heading3 text-lg-left mb-3">Vault</h2>
                                        {/* <p className="description mb-1">Transactions details can be downloaded <a href="https://cryptoamulets.mypinata.cloud/ipfs/QmTRZm3WDZDaFakGkcA7TLZc39VXMAUGXn7toUduXiMg6h" rel="noreferrer" target="_blank">here</a></p>
                                        <p className="description mb-1">Next buy back on 27 Dec 2021</p> */}
                                        <p className="heading-color4 mb-1 text-center d-block d-md-none">CryptoAmulets (S1) bought back using Royalty fees received are shown below.</p>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-lg-5 col-md-12 text-center pl-1 d-none d-lg-block">
                                    <div className="header-card cornerround h-100 p-5 d-flex align-items-center justify-content-center">
                                        <p className="description text-center"><span className="heading-color4">CryptoAmulets (S1) bought back using Royalty fees received are shown below.</span></p>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="all-cards justify-content-start">
                                {renderBoughtList()}
                            </div>
                            { this.state.pageCountB > 1 ? (
                            <div className="my-pagination">
                                <ReactPaginate
                                    previousLabel={''}
                                    nextLabel=''
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pageCountB}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={2}
                                    onPageChange={this.handleBoughtPageClick}
                                    containerClassName={'pagination justify-content-end'}
                                    activeClassName={'active'}
                                />
                            </div>
                            ) : ('')}
                        </div>
                    </div>
                </section>
            </article>
        )
    }
}

export default Vault;