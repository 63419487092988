import React from 'react';
import pressHeaderIcon from './../../assets/images/press-header-icon.svg';
import afpIcon from './../../assets/images/afp.svg';
import thaigerIcon from './../../assets/images/thaiger.svg';
import bangkogIcon from './../../assets/images/bangkog.svg';
import yahooIcon from './../../assets/images/yahoo.svg';
import standardIcon from './../../assets/images/standard.svg';
import matichonIcon from './../../assets/images/matichon.svg';
import matterIcon from './../../assets/images/thematter.svg';
import eqIcon from './../../assets/images/exoticquixotic.svg';

const Press = (props) => {
    return (
        <article>
            <section id="press" className="text-center pt-2 pb-5">
                <div className="container section-container position-relative">
                    <div className="row pb-3 pb-lg-4">
                        <div className="col-xl-7 col-lg-7 col-md-12 text-center text-lg-left pr-lg-1">
                            <div className="header-card cornerround h-100 p-3 p-lg-5">
                                <h2 className="sec-heading mb-2 mb-lg-4 text-lg-left">Press</h2>
                                <img src={pressHeaderIcon} alt="Amulets FAQ" className="faq-header-icon d-block d-lg-none w-25 m-auto pt-3" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-12 text-center pl-1 d-none d-lg-block">
                            <div className="header-card cornerround h-100 p-5">
                                <img src={pressHeaderIcon} alt="Amulets FAQ" className="press-header-icon" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container section-container position-relative pb-5">
                    <div className="row px-3">
                        <div className="col-lg-6 px-0 px-lg-5">
                            <div className="press-item d-flex align-items-center py-3 py-lg-4">
                                <div className="press-icon">
                                    <img className="img-fluid" src={yahooIcon} alt="CryptoAmulets Press - yahoo.com" />
                                </div>
                                <div className="press-text text-left ml-3 ml-lg-4">
                                    <div className="press-source mb-3">yahoo.com</div>
                                    <div className="press-link">
                                        <a href="https://news.yahoo.com/buddhist-digital-amulets-mark-thai-024324236.html?guccounter=1"  target="_blank" rel="noreferrer">Buddhist digital amulets mark Thai entry into crypto art craze.</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-0 px-lg-5">
                            <div className="press-item d-flex align-items-center py-3 py-lg-4">
                                <div className="press-icon">
                                    <img className="img-fluid" src={thaigerIcon} alt="CryptoAmulets Press - thethaiger.com" />
                                </div>
                                <div className="press-text text-left ml-3 ml-lg-4">
                                    <div className="press-source mb-3">thethaiger.com</div>
                                    <div className="press-link">
                                        <a href="https://thethaiger.com/hot-news/technology/traditional-meets-modern-with-buddhist-nfts-cryptoamulets"  target="_blank" rel="noreferrer">Traditional meets modern with Buddhist NFTs CryptoAmulets | Thaiger.</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3">
                        <div className="col-lg-6 px-0 px-lg-5">
                            <div className="press-item d-flex align-items-center py-3 py-lg-4">
                                <div className="press-icon">
                                    <img className="img-fluid" src={afpIcon} alt="CryptoAmulets Press - yahoo.com" />
                                </div>
                                <div className="press-text text-left ml-3 ml-lg-4">
                                    <div className="press-source mb-3">afpbb.com</div>
                                    <div className="press-link">
                                        <a href="https://www.afpbb.com/articles/-/3355919?page=2"  target="_blank" rel="noreferrer">仏教界もデジタル化 高僧祈念のNFTお守り登場 タイ</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-0 px-lg-5">
                            <div className="press-item d-flex align-items-center py-3 py-lg-4">
                                <div className="press-icon">
                                    <img className="img-fluid" src={bangkogIcon} alt="CryptoAmulets Press - bangkokpost.com" />
                                </div>
                                <div className="press-text text-left ml-3 ml-lg-4">
                                    <div className="press-source mb-3">bangkokpost.com</div>
                                    <div className="press-link">
                                        <a href="https://www.bangkokpost.com/thailand/general/2146175/buddhist-digital-amulets-mark-thai-entry-into-crypto-art-craze"  target="_blank" rel="noreferrer">Buddhist digital amulets mark Thai entry into crypto art craze.</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3">
                        <div className="col-lg-6 px-0 px-lg-5">
                            <div className="press-item d-flex align-items-center py-3 py-lg-4">
                                <div className="press-icon">
                                    <img className="img-fluid" src={matterIcon} alt="CryptoAmulets Press - thematter.co" />
                                </div>
                                <div className="press-text text-left ml-3 ml-lg-4">
                                    <div className="press-source mb-3">thematter.co</div>
                                    <div className="press-link">
                                        <a href="https://thematter.co/social/talk-with-crypto-amulets/157751"  target="_blank" rel="noreferrer">เมื่อพระเครื่องไทยก้าวสู่บล็อกเชน คุยกับ CryptoAmulets ว่าด้วยพระเครื่องในยุคดิจิทัล</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-0 px-lg-5">
                            <div className="press-item d-flex align-items-center py-3 py-lg-4">
                                <div className="press-icon">
                                    <img className="img-fluid" src={eqIcon} alt="CryptoAmulets Press - exoticquixotic.com" />
                                </div>
                                <div className="press-text text-left ml-3 ml-lg-4">
                                    <div className="press-source mb-3">exoticquixotic.com</div>
                                    <div className="press-link">
                                        <a href="https://exoticquixotic.com/culture/crypto-amulets/"  target="_blank" rel="noreferrer">“CRYPTO AMULETS” ความชอบและการส่งต่อความเชื่อแบบไร้พรมแดน</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row px-3">
                        <div className="col-lg-6 px-0 px-lg-5">
                            <div className="press-item d-flex align-items-center py-3 py-lg-4">
                                <div className="press-icon">
                                    <img className="img-fluid" src={standardIcon} alt="CryptoAmulets Press - thestandard.co" />
                                </div>
                                <div className="press-text text-left ml-3 ml-lg-4">
                                    <div className="press-source mb-3">thestandard.co</div>
                                    <div className="press-link">
                                        <a href="https://thestandard.co/crypto-amulets/"  target="_blank" rel="noreferrer">พลิกโฉมวงการพระเครื่อง ‘Crypto Amulets’ ครั้งแรกของพระเครื่องในรูปแบบของ NFT</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 px-0 px-lg-5">
                            <div className="press-item d-flex align-items-center py-3 py-lg-4">
                                <div className="press-icon">
                                    <img className="img-fluid" src={matichonIcon} alt="CryptoAmulets Press - matichon.co.th" />
                                </div>
                                <div className="press-text text-left ml-3 ml-lg-4">
                                    <div className="press-source mb-3">matichon.co.th</div>
                                    <div className="press-link">
                                        <a href="https://www.matichon.co.th/region/news_2768135"  target="_blank" rel="noreferrer">ครั้งแรกในโลก พระเครื่องดิจิตัล 'NFT CryptoAmulets' เชิญหลวงปู่เฮงร่วมพิธี ราคาพุ่งแตะล้าน</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </article>
    )
}

export default Press;