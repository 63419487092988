import React from 'react';

const ListTraits = (props) => {
    const { item } = props;

    if (item.value !== '') {
        return (
            <div className="col-lg-4 col-md-6">
                <div className="item-box">
                    <div className="ball-icon"></div>
                    <div className="info">
                        <p className="legend">{item.trait_type}</p>
                        <div className="name">{item.value}</div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default ListTraits;